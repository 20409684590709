import React, { ReactElement, useEffect, useState } from 'react';
import { Control, Controller, useForm, useFormState } from 'react-hook-form';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  Grid,
  StyleRules,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import i18n from 'i18next';

import { saveConsultantVerification } from '../../actions/consultant/consultantVerificationActions';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import { createConsultantMyIntroduction } from '../../views/consultant/consultant.model';
import { GeneralDialog } from '../Dialog';
const styles = (): StyleRules =>
  createStyles({
    helperText: {
      color: 'red',
    },
    submit: {
      textAlign: 'center',
    },
    AvatarBorder: {
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    buttonHeight: {
      margin: 'auto 0',
      height: '36.5px',
    },
    avatarColor: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    contentTop: {
      paddingTop: '20px',
    },
  });
const IntroduceView = ({ classes, onClose }: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const verificationId = consultant?.verification?.id;

  const { t } = useTranslation();
  i18n.loadNamespaces('LanguagesInputView');

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      otherInfo: createConsultantMyIntroduction(),
    },
    mode: 'onChange',
  });
  const { isDirty, errors, isValid } = useFormState({ control });

  const savaInfo = async () => {
    if (isValid) {
      dispatch(
        saveConsultantVerification({
          id: verificationId,
          consultantType: 'PERSONAL',
          otherInfo: getValues('otherInfo'),
          status: 'LANGUAGE_REGISTERED',
        })
      );
      setIsDialogOpen(false);
      onClose && onClose();
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('数据格式不正确'),
        })
      );
    }
  };

  useEffect(() => {
    if (consultant.verification?.otherInfo) {
      console.log(consultant.verification?.basicInfo);
      setValue('otherInfo', consultant.verification?.otherInfo);
    }
  }, [consultant.verification?.basicInfo]);
  return (
    <>
      <Grid item container xs={12} className={classes.contentTop}>
        <Controller
          render={({ field }) => (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              placeholder="请输入个人简介"
              minRows={4}
              maxRows={4}
              required
              error={!!errors.otherInfo?.description}
              helperText={errors?.otherInfo?.description && '请输入个人简介'}
              id="description"
              label={t('my_introduction:my_introduction_description')}
              {...field}
            />
          )}
          name="otherInfo.description"
          control={control}
          rules={{
            required: true,
          }}
        ></Controller>
      </Grid>
      <div className={classes.buttonContent}>
        <Button
          color="primary"
          onClick={() => {
            onClose && onClose();
          }}
        >
          取消
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (isDirty) {
              trigger('otherInfo');
              setIsDialogOpen(true);
            }
          }}
        >
          确认修改
        </Button>
      </div>

      <GeneralDialog
        isOpen={isDialogOpen}
        titleText="修改信息"
        contentText="确认要修改这些信息吗？"
        handleEdit={(): void => {
          savaInfo();
        }}
        handleDialogClose={(): void => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};
export type ConsultantBasicInfoControl = Control<{ basicInfo: ConsultantBasicInfo }>;
export type ConsultantBasicInfoSetValue = UseFormSetValue<{ basicInfo: ConsultantBasicInfo }>;
export type ConsultantBasicInfoGetValue = UseFormGetValues<{ basicInfo: ConsultantBasicInfo }>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
}

export default withStyles(styles)(IntroduceView);
