import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StyleRules,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18n from 'i18next';

import { saveConsultantVerification } from '../../actions/consultant/consultantVerificationActions';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import educationBackground from '../../utils/educationBackground';
import specializedBackground from '../../utils/specialized';
import { createConsultantEducationInfo } from '../../views/consultant/consultant.model';
import { educationEditContentType } from '../../views/consultant/ResumeView';
import { GeneralDialog } from '../Dialog';

const styles = (): StyleRules =>
  createStyles({
    borders: {
      position: 'relative',
      verticalAlign: 'middle',
      border: '1px solid #a2a294',
    },
    helperText: {
      color: 'red',
    },
    submit: {
      textAlign: 'center',
    },
    AvatarBorder: {
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    buttonHeight: {
      margin: 'auto 0',
      height: '36.5px',
    },
    avatarColor: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      margin: '20px 0',
    },
    contentTop: {
      paddingBottom: '20px',
    },
    cardWidth: {
      width: '100%',
      height: '40px',
      paddingTop: '8px',
    },
  });
const educationView = ({ classes, onClose, editStatus, editContent }: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const verificationId = consultant?.verification?.id;
  const { t } = useTranslation();
  i18n.loadNamespaces('EducationInputView');

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      educationInfoList: createConsultantEducationInfo(),
    },
    mode: 'onChange',
  });

  const { isDirty, errors, isValid } = useFormState({ control });

  const savaInfo = async () => {
    if (isValid) {
      if (editStatus == 'add') {
        const educationInfoListData = [
          ...JSON.parse(JSON.stringify(consultant.verification?.educationInfoList)),
          getValues('educationInfoList'),
        ];
        for (let i = 0; i < educationInfoListData.length; i++) {
          if (
            educationInfoListData[i].atSchoolDateRangeStart &&
            typeof educationInfoListData[i].atSchoolDateRangeStart == 'string'
          ) {
            educationInfoListData[i].atSchoolDateRangeStart = new Date(
              educationInfoListData[i].atSchoolDateRangeStart
            );
          }
          if (
            educationInfoListData[i].atSchoolDateRangeEnd &&
            typeof educationInfoListData[i].atSchoolDateRangeEnd == 'string'
          ) {
            educationInfoListData[i].atSchoolDateRangeEnd = new Date(
              educationInfoListData[i].atSchoolDateRangeEnd
            );
          }
        }
        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            educationInfoList: educationInfoListData,
            status: 'LANGUAGE_REGISTERED',
          })
        );
      }
      if (editStatus == 'edit') {
        const saveEditData = JSON.stringify(consultant.verification?.educationInfoList);
        const educationInfoListData = JSON.parse(saveEditData);
        if (editContent?.index != undefined && editContent?.index != null) {
          educationInfoListData[editContent.index] = getValues('educationInfoList');

          for (let i = 0; i < educationInfoListData.length; i++) {
            if (
              educationInfoListData[i].atSchoolDateRangeStart &&
              typeof educationInfoListData[i].atSchoolDateRangeStart == 'string'
            ) {
              educationInfoListData[i].atSchoolDateRangeStart = new Date(
                educationInfoListData[i].atSchoolDateRangeStart
              );
            }
            if (
              educationInfoListData[i].atSchoolDateRangeEnd &&
              typeof educationInfoListData[i].atSchoolDateRangeEnd == 'string'
            ) {
              educationInfoListData[i].atSchoolDateRangeEnd = new Date(
                educationInfoListData[i].atSchoolDateRangeEnd
              );
            }
          }
          dispatch(
            saveConsultantVerification({
              id: verificationId,
              consultantType: 'PERSONAL',
              educationInfoList: educationInfoListData,
              status: 'LANGUAGE_REGISTERED',
            })
          );
        }
      }

      setIsDialogOpen(false);
      onClose && onClose();
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('数据格式不正确'),
        })
      );
    }
  };

  useEffect(() => {
    if (editContent && editStatus == 'edit') {
      setValue('educationInfoList', editContent.educationEditContent);
      setValue('educationInfoList.education', editContent.educationEditContent.education);
    }
  }, [editContent]);

  return (
    <>
      <Grid item container xs={12} className={classes.contentTop}></Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  autoFocus={false}
                  label={t('education_input_view:school_name')}
                  error={!!errors?.educationInfoList?.schoolName}
                  helperText={
                    !!errors?.educationInfoList?.schoolName &&
                    t('education_input_view:school_not_empty')
                  }
                  {...field}
                />
              </>
            )}
            name={`educationInfoList.schoolName`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="label-education">{t('education_input_view:diploma')}</InputLabel>
                  <Select
                    error={!!errors?.educationInfoList?.education}
                    labelId="label-education"
                    id="education"
                    label="学历"
                    fullWidth
                    {...field}
                  >
                    <MenuItem key={''} value={''} disabled></MenuItem>
                    {educationBackground.map(edbg => {
                      return (
                        <MenuItem key={edbg.value} value={edbg.value}>
                          {edbg.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors?.educationInfoList?.education && (
                    <FormHelperText className={classes.errColor}>
                      {t('education_input_view:education_not_empty')}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
            name={`educationInfoList.education`}
            rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between" style={{ marginTop: '1rem' }}>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  defaultValue={null}
                  size="small"
                  id="combo-box-demo"
                  options={specializedBackground}
                  getOptionLabel={option => option.label}
                  getOptionDisabled={option => option.disabled == true}
                  fullWidth
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        label={t('education_input_view:professional_classification')}
                        variant="outlined"
                      />
                    );
                  }}
                  value={specializedBackground.find(option => option.value === field.value) || null} // 设置 Autocomplete 的 value 属性
                  onChange={(_, newValue) => {
                    field.onChange(newValue?.value || null); // 更新 React Hook Form 字段值
                  }}
                />
              </FormControl>
            )}
            name={`educationInfoList.majorType`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <>
                <TextField
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  autoFocus={false}
                  label={t('education_input_view:professional_name')}
                  error={!!errors?.educationInfoList?.academicDirections}
                  helperText={
                    errors?.educationInfoList?.academicDirections &&
                    t('education_input_view:major_not_empty')
                  }
                  {...field}
                />
              </>
            )}
            name={`educationInfoList.academicDirections`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="space-between" style={{ marginTop: '1rem' }}>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      size="small"
                      error={!!errors?.educationInfoList?.atSchoolDateRangeStart}
                      variant="inline"
                      format="yyyy-MM"
                      views={['year', 'month']}
                      id="date-picker-start"
                      label={t('education_input_view:admission_date')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      {...field}
                      value={field.value ?? null}
                    />
                  </MuiPickersUtilsProvider>
                  {errors?.educationInfoList?.atSchoolDateRangeStart && (
                    <FormHelperText className={classes.errColor}>
                      {t('education_input_view:enrollment_not_empty')}
                    </FormHelperText>
                  )}
                </>
              );
            }}
            name={`educationInfoList.atSchoolDateRangeStart`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    size="small"
                    variant="inline"
                    format="yyyy-MM"
                    views={['year', 'month']}
                    id="date-picker-end"
                    label={t('education_input_view:graduation_time')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    {...field}
                    value={field.value ?? null}
                  />
                </MuiPickersUtilsProvider>
              </>
            )}
            name={`educationInfoList.atSchoolDateRangeEnd`}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      <div className={classes.buttonContent}>
        <Button
          color="primary"
          onClick={() => {
            onClose && onClose();
          }}
        >
          取消
        </Button>
        {editStatus === 'edit' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('educationInfoList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认修改
          </Button>
        )}
        {editStatus === 'add' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('educationInfoList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认添加
          </Button>
        )}
      </div>
      <GeneralDialog
        isOpen={isDialogOpen}
        titleText="修改信息"
        contentText="确认要修改这些信息吗？"
        handleEdit={(): void => {
          savaInfo();
        }}
        handleDialogClose={(): void => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  editStatus?: string;
  editContent?: educationEditContentType;
}

export default withStyles(styles)(educationView);
