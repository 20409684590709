import React, { ReactElement, useEffect, useState } from 'react';
import { Control, Controller, useForm, useFormState } from 'react-hook-form';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StyleRules,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import imageCompression from 'browser-image-compression';
import debug from 'debug';
import i18n from 'i18next';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';

import { saveConsultantVerification } from '../../actions/consultant/consultantVerificationActions';
import logo from '../../images/fintegrity-logo.jpeg';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import CITY from '../../utils/city';
import countyLess from '../../utils/cityChild';
import COUNTRY from '../../utils/country';
import PROVINCE from '../../utils/province';
import { City, createConsultantBasicInfo } from '../../views/consultant/consultant.model';
import { GeneralDialog } from '../Dialog';
const styles = (): StyleRules =>
  createStyles({
    helperText: {
      color: 'red',
    },
    submit: {
      textAlign: 'center',
    },
    AvatarBorder: {
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    buttonHeight: {
      margin: 'auto 0',
      height: '36.5px',
    },
    avatarColor: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    contentTop: {
      paddingTop: '20px',
    },
  });
const ResumePersonInfoView = ({ classes, onClose }: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const verificationId = consultant?.verification?.id;
  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      basicInfo: createConsultantBasicInfo(),
    },
    mode: 'onChange',
  });
  const { isDirty, errors, isValid } = useFormState({ control });

  const { t } = useTranslation();
  i18n.loadNamespaces('personal_info_input_view');

  const provinceList: Array<{ code: string; name: string; en: string }> = PROVINCE;
  const cityList: Array<City> = CITY;
  const county: Array<{
    code: string;
    name: string;
    cityCode: string;
    enName: string;
  }> = countyLess;

  const [selectProvinceChar, setSelectProvinceChar] = useState<string | number>();
  const [selectCityChar, setSelectCityChar] = useState<string | number>();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);
  const [avatarName, setAvatarName] = useState('');
  const [avatarImg, setAvatarImg] = useState('');

  const compressFile = async (fileToCompress: File): Promise<string> => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let compressedFile: File;
    try {
      compressedFile = await imageCompression(fileToCompress, options);
      debug(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    } catch (error) {
      // debug('Compress file', error);
    }
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = (): void => resolve(reader.result as string);
      reader.readAsDataURL(compressedFile);
    });
  };

  const handleOnSave = async (fileObjects: FileObject[]): Promise<void> => {
    setIsUploadDialogOpen(false);
    if (avatarName == 'Avatar') {
      if (fileObjects.length == 1) {
        const AvatarData = await compressFile(fileObjects[0].file);
        setAvatarImg(AvatarData);
        setValue('basicInfo.avatar', AvatarData, {
          shouldDirty: true,
        });
        setAvatarName('');
        return;
      } else {
        dispatch(
          showNotificationAction({
            severity: 'error',
            message: t('personal_info_input_view:cannot_multiple_avatars'),
          })
        );
        return;
      }
    }
  };

  const AvatarHandle = (): void => {
    setAvatarName('Avatar');
    setIsUploadDialogOpen(true);
  };
  const savaInfo = async () => {
    if (isValid) {
      setValue('basicInfo.birthday', new Date(String(getValues('basicInfo.birthday'))));
      dispatch(
        saveConsultantVerification({
          id: verificationId,
          consultantType: 'PERSONAL',
          basicInfo: getValues('basicInfo'),
          status: 'PERSONAL_REGISTERED',
        })
      );

      setIsDialogOpen(false);
      onClose && onClose();
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('数据格式不正确'),
        })
      );
    }
  };
  useEffect(() => {
    if (i18n.language == 'zhCN') {
      COUNTRY.sort((a, b) => a.name.localeCompare(b.name));
      provinceList.sort((a, b) => a.name.localeCompare(b.name));
    } else if (i18n.language == 'enUS') {
      COUNTRY.sort((a, b) => a.value.localeCompare(b.value));
      provinceList.sort((a, b) => a.en.localeCompare(b.en));
    }
  }, [i18n.language]);

  useEffect(() => {
    if (consultant.verification?.basicInfo) {
      setValue('basicInfo', consultant.verification.basicInfo);
      consultant.verification.basicInfo.nowAddressProvince &&
        setSelectProvinceChar(consultant.verification.basicInfo.nowAddressProvince);
      consultant.verification.basicInfo.nowAddressCity &&
        setSelectCityChar(consultant.verification.basicInfo.nowAddressCity);
    }
  }, [consultant.verification?.basicInfo]);
  return (
    <>
      <Grid item container xs={12} className={classes.contentTop}>
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item container xs={3}>
            <div className={classes.avatarColor}>
              <Controller
                render={({ field: { value } }) => (
                  <img
                    onClick={AvatarHandle}
                    className={classes.AvatarBorder}
                    src={value ? value : logo}
                  />
                )}
                name="basicInfo.avatar"
                control={control}
              ></Controller>
              <div>
                {avatarImg != '' ? (
                  <Typography variant="caption" display="block" gutterBottom>
                    {t('personal_info_input_view:edit_avatar')}
                  </Typography>
                ) : (
                  <Typography variant="caption" display="block" gutterBottom>
                    {t('personal_info_input_view:upload_avatar')}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          <Grid item container xs={9}>
            <Grid item container xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="名字"
                    error={!!errors?.basicInfo?.firstName}
                    helperText={
                      errors?.basicInfo?.firstName &&
                      t('personal_info_input_view:first_name_not_empty')
                    }
                    {...field}
                  />
                )}
                name="basicInfo.firstName"
                control={control}
                rules={{
                  required: true,
                }}
              ></Controller>
            </Grid>
            <Grid item container xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="LastName"
                    label="姓氏"
                    error={!!errors?.basicInfo?.lastName}
                    helperText={
                      errors?.basicInfo?.lastName && t('personal_info_input_view:name_not_empty')
                    }
                    {...field}
                  />
                )}
                name="basicInfo.lastName"
                control={control}
                rules={{
                  required: true,
                }}
              ></Controller>
            </Grid>
            <Grid item container xs={12}>
              <Controller
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <FormControl
                      margin="normal"
                      variant="outlined"
                      error={!!errors?.basicInfo?.birthday}
                      style={{ height: '40px', margin: '0' }}
                      fullWidth
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          size="small"
                          error={!!errors?.basicInfo?.birthday}
                          fullWidth
                          label="出生日期"
                          format="yyyy-MM-dd"
                          views={['year', 'month', 'date']}
                          variant="inline"
                          name={name}
                          value={value ? value : null}
                          inputRef={ref}
                          onChange={onChange}
                          autoOk={true}
                        />
                      </MuiPickersUtilsProvider>
                      {errors?.basicInfo?.birthday && (
                        <FormHelperText>
                          {t('personal_info_input_view:birthday_not_empty')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
                rules={{
                  required: true,
                }}
                name="basicInfo.birthday"
                control={control}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl
                margin="normal"
                variant="outlined"
                size="small"
                error={!!errors?.basicInfo?.gender}
                fullWidth
              >
                <InputLabel id="label-gender">{t('personal_info_input_view:gender')}</InputLabel>
                <Select
                  labelId="label-gender"
                  id="gender"
                  label={t('personal_info_input_view:gender')}
                  fullWidth
                  {...field}
                >
                  <MenuItem value={''} disabled>
                    请选择
                  </MenuItem>
                  <MenuItem value={'male'}>男</MenuItem>
                  <MenuItem value={'female'}>女</MenuItem>
                </Select>
                {errors?.basicInfo?.gender && (
                  <FormHelperText>{t('personal_info_input_view:gender_not_empty')}</FormHelperText>
                )}
              </FormControl>
            )}
            name="basicInfo.gender"
            rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                size="small"
                error={!!errors?.basicInfo?.nationality}
              >
                <InputLabel id="label-nationality">
                  {t('personal_info_input_view:nationality')}
                </InputLabel>
                <Select
                  labelId="label-nationality"
                  id="nationality"
                  label={t('personal_info_input_view:nationality')}
                  fullWidth
                  {...field}
                >
                  <MenuItem key={''} value={''} disabled>
                    请选择
                  </MenuItem>
                  {COUNTRY.map(country => {
                    return (
                      <MenuItem key={country.value} value={country.value}>
                        {i18n.language == 'enUS' ? country.value : country.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors?.basicInfo?.nationality && (
                  <FormHelperText>
                    {t('personal_info_input_view:nationality_not_blank')}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            name="basicInfo.nationality"
            control={control}
          ></Controller>
        </Grid>

        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                required
                size="small"
                fullWidth
                id="contactEmail"
                label="邮箱"
                error={!!errors?.basicInfo?.contactEmail}
                helperText={
                  errors?.basicInfo?.contactEmail &&
                  (errors?.basicInfo?.contactEmail?.type === 'required'
                    ? t('personal_info_input_view:email_not_empty')
                    : t('personal_info_input_view:incorrect_email_format'))
                }
                {...field}
              />
            )}
            name="basicInfo.contactEmail"
            control={control}
            rules={{
              required: true,
              pattern: /^[A-Za-z0-9_-]+([._-][A-Za-z0-9_-]+)*@([A-Za-z0-9-]+[.])+[A-Za-z]{2,}$/,
            }}
          ></Controller>
        </Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                required
                size="small"
                fullWidth
                id="phoneNumber"
                label={t('personal_info_input_view:phone')}
                error={!!errors?.basicInfo?.phoneNumber}
                helperText={
                  !!errors?.basicInfo?.phoneNumber &&
                  (errors?.basicInfo?.phoneNumber?.type === 'required'
                    ? '请输入手机号'
                    : '请输入正确的手机号格式')
                }
                {...field}
              />
            )}
            name="basicInfo.phoneNumber"
            control={control}
            rules={{
              required: true,
              pattern: /^1[3-9]\d{9}$/,
            }}
          ></Controller>
        </Grid>

        <Grid item container xs={12} justifyContent="space-between">
          <Grid item xs={4}>
            <Controller
              render={({ field: { name, value, onChange, ref } }) => (
                <FormControl
                  margin="normal"
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors?.basicInfo?.nowAddressProvince}
                >
                  <InputLabel id="label-nowAddress-province">
                    {t('personal_info_input_view:province')}
                  </InputLabel>
                  <Select
                    labelId="label-nowAddress-province"
                    id="province"
                    label="行政省"
                    value={value}
                    name={name}
                    inputRef={ref}
                    onChange={e => {
                      onChange(e);
                      setSelectProvinceChar(e.target.value as string);
                      setValue('basicInfo.nowAddressDistrict', '');
                      setValue('basicInfo.nowAddressCity', '');
                    }}
                  >
                    <MenuItem key={''} value={''} disabled>
                      -{t('personal_info_input_view:please_select')}-
                    </MenuItem>
                    {[...provinceList].map(province => {
                      return (
                        <MenuItem key={province.code} value={province.code}>
                          {i18n.language == 'enUS' ? province.en : province.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors?.basicInfo?.passportType && <FormHelperText>选择省</FormHelperText>}
                </FormControl>
              )}
              name="basicInfo.nowAddressProvince"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid item xs={4}>
            <Controller
              render={({ field: { name, value, onChange } }) => (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  size="small"
                  error={!!errors?.basicInfo?.nowAddressCity}
                  fullWidth
                >
                  <InputLabel id="label-nowAddress-city">
                    {t('personal_info_input_view:city')}
                  </InputLabel>
                  <Select
                    labelId="label-nowAddress-city"
                    id="city"
                    label="行政市"
                    value={value}
                    name={name}
                    onChange={e => {
                      onChange(e);
                      setSelectCityChar(e.target.value as string);
                      setValue('basicInfo.nowAddressDistrict', '');
                    }}
                  >
                    <MenuItem key={''} value={''} disabled>
                      -{t('personal_info_input_view:please_select')}-
                    </MenuItem>
                    {[...cityList]
                      .filter(city => city.provinceCode === selectProvinceChar)
                      .map(city => {
                        return (
                          <MenuItem key={city.code} value={city.code}>
                            {i18n.language == 'enUS' ? city.enName : city.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {errors?.basicInfo?.nowAddressCity && <FormHelperText>选择市</FormHelperText>}
                </FormControl>
              )}
              name="basicInfo.nowAddressCity"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid item xs={4}>
            <Controller
              render={({ field }) => (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  size="small"
                  error={!!errors?.basicInfo?.nowAddressDistrict}
                  fullWidth
                >
                  <InputLabel id="label-nowAddress-nowAddressDistrict">
                    {t('personal_info_input_view:now_address_district')}
                  </InputLabel>
                  <Select
                    labelId="label-nowAddress-nowAddressDistrict"
                    id="nowAddressDistrict"
                    label="行政区县"
                    {...field}
                  >
                    <MenuItem key={''} value={''} disabled>
                      -{t('personal_info_input_view:please_select')}-
                    </MenuItem>
                    {[...county]
                      .filter(city => city.cityCode === selectCityChar)
                      .map(city => {
                        return (
                          <MenuItem key={city.code} value={city.code}>
                            {i18n.language == 'enUS' ? city.enName : city.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {errors?.basicInfo?.nowAddressDistrict && (
                    <FormHelperText>选择区县</FormHelperText>
                  )}
                </FormControl>
              )}
              name="basicInfo.nowAddressDistrict"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.buttonContent}>
        <Button
          color="primary"
          onClick={() => {
            onClose && onClose();
          }}
        >
          取消
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (isDirty) {
              trigger('basicInfo');
              setIsDialogOpen(true);
            }
          }}
        >
          确认修改
        </Button>
      </div>

      <DropzoneDialogBase
        fileObjects={[]}
        open={isUploadDialogOpen}
        onAdd={(fileObjects): void => {
          handleOnSave(fileObjects);
        }}
        acceptedFiles={['.jpg,.jpeg,.bmp,.png']}
        showPreviews={true}
        maxFileSize={2097152}
        dropzoneText={t('personal_info_input_view:drag_files')}
        dialogTitle={t('personal_info_input_view:uploading')}
        submitButtonText={t('personal_info_input_view:uploading')}
        cancelButtonText={t('personal_info_input_view:cancellation')}
        onClose={(): void => {
          setIsUploadDialogOpen(false);
        }}
      />
      <GeneralDialog
        isOpen={isDialogOpen}
        titleText="修改信息"
        contentText="确认要修改这些信息吗？"
        handleEdit={(): void => {
          savaInfo();
        }}
        handleDialogClose={(): void => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};
export type ConsultantBasicInfoControl = Control<{ basicInfo: ConsultantBasicInfo }>;
export type ConsultantBasicInfoSetValue = UseFormSetValue<{ basicInfo: ConsultantBasicInfo }>;
export type ConsultantBasicInfoGetValue = UseFormGetValues<{ basicInfo: ConsultantBasicInfo }>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
}

export default withStyles(styles)(ResumePersonInfoView);
