import { ANONYMOUS_GET_CONSULTANT } from '../components/PdfTemplate';
import { loadingEndAction, loadingStartAction } from '../reducers/loadingReducer';
import { hideNotificationAction, showNotificationAction } from '../reducers/notificationReducer';
import { apiVersionKnownAction } from '../reducers/versionReducer';
import debug from '../utils/debug';
import { query } from '../utils/graphql';
import { GET_API_INFO } from '../utils/graphql/app';

export const getApiVersion: Function = () => async (dispatch: Function): Promise<boolean> => {
  debug('Getting API version');
  const { data } = await query(GET_API_INFO);
  if (data?.getApiVersion) {
    dispatch(apiVersionKnownAction(data?.getApiVersion));
    return true;
  }
  dispatch(showNotificationAction({ severity: 'warning', message: 'Failed to get API version' }));
  return false;
};

export const anonymousGetConsultantById: Function = (consultantId: number) => async (
  dispatch: Function
): Promise<ConsultantVerification> => {
  debug('anonymousGetConsultantById');
  console.log('arguments', consultantId);
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = {
    consultantId,
  };
  const { data, loading, error } = await query(ANONYMOUS_GET_CONSULTANT, variables);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取个人信息失败' }));
    return {} as ConsultantVerification;
  }

  if (data && data?.anonymousGetConsultantById) {
    const anonymousGetConsultantById = data.anonymousGetConsultantById;
    debug('Get anonymousGetConsultantById successfully');
    debug(anonymousGetConsultantById);
    dispatch(loadingEndAction());

    return anonymousGetConsultantById.verification;
  }

  return {} as ConsultantVerification;
};
