import { gql } from '@apollo/client';

const ADD_CONSULTANT_VERIFICATION = gql`
  mutation MUTATION($verification: FConsultantVerificationInput) {
    consultantAddVerification(verification: $verification) {
      id
      consultantType
      status
      consultant {
        id
      }
      basicInfo {
        id
        nationality
        regionProvince
        regionCity
        lastName
        firstName
        avatar
        birthday
        phoneNumber
        gender
        birthYear
        birthMonth
        passportType
        passportId
        personalDescriptionFileList {
          id
          file
        }
        contactEmail
        nowAddressProvince
        nowAddressCity
        nowAddressDetail
        nowAddressDistrict
        personalDescription
      }
      educationInfoList {
        id
        education
        schoolName
        majorType
        academicDirections
        atSchoolDateRangeStart
        atSchoolDateRangeEnd
        educationFileList {
          id
          file
        }
      }
      workInfoList {
        id
        companyName
        wayOfWorking
        jobTitle
        industryClass
        professionalClass
        atWorkDateRangeStart
        atWorkDateRangeEnd
        jobDescription
      }
      projectInfoList {
        id
        projectDateStart
        projectCompanyName
        projectImageFileList {
          id
          file
        }
        projectDateEnd
        projectName
        projectDescription
      }
      languageInfoList {
        id
        languageLevel
        languageName
      }
      honorInfoList {
        id
        honorTitle
        honorDateRangeStart
        honorDateRangeEnd
        honorContent
      }
      certificateInfoList {
        certificateName
        certificateDescription
        certificateFileList {
          id
          file
        }
      }
      otherInfo {
        id
        background
        description
        strength
        resume
      }
      settings {
        findingJob
        id
        workDossier
        workType
        workScenario
        expectedFeePerHour
        expectedFeePerMonth
      }
    }
  }
`;

const UPDATE_CONSULTANT_VERIFICATION = gql`
  mutation MUTATION($verification: FConsultantVerificationInput) {
    consultantUpdateVerification(verification: $verification) {
      id
      consultantType
      status
      consultant {
        id
      }
      basicInfo {
        id
        nationality
        contactEmail
        regionProvince
        regionCity
        lastName
        firstName
        avatar
        birthday
        phoneNumber
        gender
        birthYear
        birthMonth
        passportType
        passportId
        personalDescriptionFileList {
          id
          file
        }
        nowAddressProvince
        nowAddressCity
        nowAddressDetail
        nowAddressDistrict
        personalDescription
      }
      educationInfoList {
        id
        education
        schoolName
        majorType
        academicDirections
        atSchoolDateRangeStart
        atSchoolDateRangeEnd
        educationFileList {
          id
          file
        }
      }
      workInfoList {
        id
        companyName
        wayOfWorking
        jobTitle
        industryClass
        professionalClass
        atWorkDateRangeStart
        atWorkDateRangeEnd
        jobDescription
      }
      projectInfoList {
        id
        projectDateStart
        projectCompanyName
        projectImageFileList {
          id
          file
        }
        projectDateEnd
        projectName
        projectDescription
      }
      languageInfoList {
        id
        languageLevel
        languageName
      }
      honorInfoList {
        id
        honorTitle
        honorDateRangeStart
        honorDateRangeEnd
        honorContent
      }
      certificateInfoList {
        certificateName
        certificateDescription
        certificateFileList {
          id
          file
        }
      }
      otherInfo {
        id
        background
        description
        strength
        resume
      }
      settings {
        findingJob
        id
        workDossier
        workType
        workScenario
        expectedFeePerHour
        expectedFeePerMonth
      }
    }
  }
`;

const GET_CONSULTANT_VERIFICATION = gql`
  query QUERY {
    consultantGetVerification {
      id
      consultantType
      status
      consultant {
        id
      }
      basicInfo {
        id
        nationality
        regionProvince
        regionCity
        firstName
        lastName
        avatar
        phoneNumber
        birthday
        gender
        birthYear
        birthMonth
        passportType
        passportId
        personalDescriptionFileList {
          id
          file
        }
        contactEmail
        nowAddressProvince
        nowAddressCity
        nowAddressDetail
        nowAddressDistrict
        personalDescription
      }
      educationInfoList {
        id
        education
        schoolName
        majorType
        academicDirections
        atSchoolDateRangeStart
        atSchoolDateRangeEnd
        educationFileList {
          id
          file
        }
      }
      workInfoList {
        id
        companyName
        wayOfWorking
        jobTitle
        industryClass
        professionalClass
        atWorkDateRangeStart
        atWorkDateRangeEnd
        jobDescription
      }
      projectInfoList {
        id
        projectDateStart
        projectCompanyName
        projectImageFileList {
          id
          file
        }
        projectDateEnd
        projectName
        projectDescription
      }
      languageInfoList {
        id
        languageLevel
        languageName
      }
      honorInfoList {
        id
        honorTitle
        honorDateRangeStart
        honorDateRangeEnd
        honorContent
      }
      certificateInfoList {
        certificateName
        certificateDescription
        certificateFileList {
          id
          file
        }
      }
      otherInfo {
        id
        background
        description
        strength
        resume
      }
      settings {
        findingJob
        id
        workDossier
        workType
        workScenario
        expectedFeePerHour
        expectedFeePerMonth
      }
    }
  }
`;

export { ADD_CONSULTANT_VERIFICATION, GET_CONSULTANT_VERIFICATION, UPDATE_CONSULTANT_VERIFICATION };
