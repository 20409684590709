import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StyleRules,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import i18n from 'i18next';

import { saveConsultantVerification } from '../../actions/consultant/consultantVerificationActions';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import LANGUAGE_WORLD from '../../utils/languageType';
import { createConsultantLanguageInfo } from '../../views/consultant/consultant.model';
import { languageEditContentType } from '../../views/consultant/ResumeView';
import { GeneralDialog } from '../Dialog';

const styles = (): StyleRules =>
  createStyles({
    borders: {
      position: 'relative',
      verticalAlign: 'middle',
      border: '1px solid #a2a294',
    },
    helperText: {
      color: 'red',
    },
    submit: {
      textAlign: 'center',
    },
    AvatarBorder: {
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    buttonHeight: {
      margin: 'auto 0',
      height: '36.5px',
    },
    avatarColor: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      margin: '20px 0',
    },
    contentTop: {
      paddingBottom: '20px',
    },
    cardWidth: {
      width: '100%',
      height: '40px',
      paddingTop: '8px',
    },
  });
const LanguageView = ({ classes, onClose, editStatus, editContent }: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const verificationId = consultant?.verification?.id;
  const { t } = useTranslation();
  i18n.loadNamespaces('LanguagesInputView');

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      languageInfoList: createConsultantLanguageInfo(),
    },
    mode: 'onChange',
  });

  const { isDirty, isValid } = useFormState({ control });

  const savaInfo = async () => {
    if (isValid) {
      if (editStatus == 'add') {
        const languageInfoListData = [
          ...JSON.parse(JSON.stringify(consultant.verification?.languageInfoList)),
          getValues('languageInfoList'),
        ];

        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            languageInfoList: languageInfoListData,
            status: 'LANGUAGE_REGISTERED',
          })
        );
      }
      if (editStatus == 'edit') {
        const saveEditData = JSON.stringify(consultant.verification?.languageInfoList);
        const languageInfoListData = JSON.parse(saveEditData);
        if (editContent?.index != undefined && editContent?.index != null) {
          languageInfoListData[editContent.index] = getValues('languageInfoList');

          dispatch(
            saveConsultantVerification({
              id: verificationId,
              consultantType: 'PERSONAL',
              languageInfoList: languageInfoListData,
              status: 'LANGUAGE_REGISTERED',
            })
          );
        }
      }

      setIsDialogOpen(false);
      onClose && onClose();
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('数据格式不正确'),
        })
      );
    }
  };

  useEffect(() => {
    if (editContent && editStatus == 'edit') {
      setValue('languageInfoList', editContent.languageEditContent);
    }
  }, [editContent]);

  return (
    <>
      <Grid item container xs={12} className={classes.contentTop}>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={5}>
            <Controller
              render={({ field }) => {
                return (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    // error={!!errors?.languageInfo?.languageSkillList?.[index]?.languageName}
                  >
                    <InputLabel id="label-education">
                      {t('languages_input_view:language')}
                    </InputLabel>
                    <Select
                      labelId="label-education"
                      id="education"
                      label="语言"
                      fullWidth
                      {...field}
                    >
                      <MenuItem key={'s1'} value={''} disabled>
                        -{t('languages_input_view:please_select')}-
                      </MenuItem>
                      {LANGUAGE_WORLD.map((lg, indexlg) => {
                        return (
                          <MenuItem key={indexlg} value={lg.value}>
                            {i18n.language == 'enUS' ? lg.value : lg.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {/* {errors?.languageInfo?.languageSkillList?.[index]?.languageName && (
                    <FormHelperText>语言不能为空</FormHelperText>
                  )} */}
                  </FormControl>
                );
              }}
              name={`languageInfoList.languageName`}
              control={control}
              // rules={{ required: true }}
            ></Controller>
          </Grid>
          <Grid item xs={5}>
            <Controller
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  // error={!!errors?.languageInfo?.languageSkillList?.[index]?.languageLevel}
                >
                  <InputLabel id="label-major">
                    {t('languages_input_view:proficiency_level')}
                  </InputLabel>
                  <Select
                    labelId="label-major"
                    id="major"
                    label={t('languages_input_view:proficiency_level')}
                    fullWidth
                    {...field}
                  >
                    <MenuItem key={'s2'} value={''} disabled>
                      -{t('languages_input_view:please_select')}-
                    </MenuItem>
                    <MenuItem key={1} value={'Beginner'}>
                      {t('languages_input_view:beginner')}
                    </MenuItem>
                    <MenuItem key={2} value={'Intermediate'}>
                      {t('languages_input_view:intermediate')}
                    </MenuItem>
                    <MenuItem key={3} value={'Advanced'}>
                      {t('languages_input_view:advanced')}
                    </MenuItem>
                    <MenuItem key={4} value={'Proficiency'}>
                      {t('languages_input_view:proficiency')}
                    </MenuItem>
                  </Select>
                  {/* {errors?.languageInfo?.languageSkillList?.[index]?.languageLevel && (
                  <FormHelperText>熟练度不能为空</FormHelperText>
                )} */}
                </FormControl>
              )}
              name={`languageInfoList.languageLevel`}
              control={control}
              // rules={{ required: true }}
            ></Controller>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.buttonContent}>
        <Button
          color="primary"
          onClick={() => {
            onClose && onClose();
          }}
        >
          取消
        </Button>
        {editStatus === 'edit' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('languageInfoList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认修改
          </Button>
        )}
        {editStatus === 'add' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('languageInfoList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认添加
          </Button>
        )}
      </div>
      <GeneralDialog
        isOpen={isDialogOpen}
        titleText="修改信息"
        contentText="确认要修改这些信息吗？"
        handleEdit={(): void => {
          savaInfo();
        }}
        handleDialogClose={(): void => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  editStatus?: string;
  editContent?: languageEditContentType;
}

export default withStyles(styles)(LanguageView);
