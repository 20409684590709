export interface TreeNode {
  value: string;
  label: string;
  children?: TreeNode[];
}
export const searchLabelsByValue = (nodes: TreeNode[], values: string[]): string[] => {
  const matchedLabels: string[] = [];

  function traverse(currentNodes: TreeNode[]) {
    currentNodes.map(node => {
      if (values.includes(node.value)) {
        matchedLabels.push(node.label);
      }
      if (node.children) {
        traverse(node.children);
      }
    });
  }

  traverse(nodes);
  return matchedLabels;
};
