export const DATE_FNS_FORMATTER = `yyyy-MM-dd'T'HH:mm:ss.SSSX`;
export const DATE_DISPLAY_FORMATTER = 'MM-dd HH:mm';
export const DATE_EXPORT_FORMATTER = 'dd.MM.yyyy HH:mm:ss';

/**
 * Common constant
 */
// Reference https://emailregex.com/
// eslint-disable-next-line no-control-regex, no-useless-escape
export const EMAIL_VALIDATION_REGEX = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const PHONE_VALIDATION_REGEX = /^1[3456789]\d{9}$/;
// export const EMAIL_VALIDATION_REGEX = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:_[a-zA-Z0-9.-]+)?$/;

/**
 * Constant for user
 */
export const USER_NOT_LOGIN = '用户未登录';
export const USER_LOGIN_FAIL = '用户登录失败';
export const USER_LOGIN_FAIL_EMAIL_INACTIVE = '邮箱未激活';
export const USER_LOGIN_FAIL_INCORRECT_USERNAME_OR_PASSWORD = '用户或密码不正确';
export const USER_TYPE_NOT_SPECIFIED = '用户类型未选择';
export const USER_MODIFIED_SUCCEED = 'Succeeded to modify';
export const USER_MODIFIED_FAIL = 'Failed to modify';

/**
 * Constant for admin
 */
export const ADMIN_GET_ALL_COMPANIES_FAIL = '获取企业失败';
export const ADMIN_APPROVE_COMPANY_VERIFICATION_FAIL = '批准企业审核申请失败';
export const ADMIN_APPROVE_COMPANY_VERIFICATION_SUCCESS = '批准企业审核申请成功';
export const ADMIN_DENY_COMPANY_VERIFICATION_FAIL = '拒绝企业审核申请失败';
export const ADMIN_DENY_COMPANY_VERIFICATION_SUCCESS = '拒绝企业审核申请成功';

/**
 * Constant for company
 */
export const COMPANY_ADD_FAIL = 'Failed to add company';
export const COMPANY_INFO_NOT_COMPLETE = '请完善企业信息';
export const COMPANY_VERIFICATION_GET_FAIL = 'Failed to get company verification';
export const COMPANY_VERIFICATION_ADD_FAIL = 'Failed to add company verification';
export const COMPANY_VERIFICATION_UPDATE_FAIL = 'Failed to update company verification';
export const COMPANY_VERIFICATION_GET_SUCCESS_ERROR_CODE = 0x030201;

/**
 * Constant for consultant
 */
export const CONSULTANT_ADD_FAIL = 'Failed to add consultant';
export const CONSULTANT_INFO_NOT_COMPLETE = '请完善顾问信息';
export const CONSULTANT_VERIFICATION_GET_SUCCESS = '获取顾问认证信息成功';
export const CONSULTANT_VERIFICATION_GET_FAIL = '获取顾问认证信息失败';
export const CONSULTANT_VERIFICATION_ADD_FAIL = 'Failed to add consultant verification';
export const CONSULTANT_VERIFICATION_UPDATE_FAIL = 'Failed to update consultant verification';
export const CONSULTANT_HOME_GET_HOT_COMPANY_FAIL = '获取热门企业失败，请刷新后重试';
export const CONSULTANT_VERIFICATION_GET_SUCCESS_ERROR_CODE = 0x030201;

/**
 * Constant for registration
 */
// export const REGISTER_EMAIL_SENT = '验证码已发往注册邮箱';
export const REGISTER_EMAIL_SENT = '注册成功';
export const REGISTER_EMAIL_SENT_ERROR = '注册码发送失败';
export const REGISTER_EMAIL_VERIFY_ERROR = '邮箱验证失败，请重试';
export const REGISTER_EMAIL_EXIST_ERROR = '该邮箱已存在，请登录';
export const AUTH_CODE_SENT = '验证码已发送';
export const AUTH_CODE_SENT_FAIL = '验证码发送失败';
