import { addHours, subHours } from 'date-fns';

export const idTimeToString: Function = (time: string) =>
  new Date(time).getTime().toString() + time.split('Z')[0].slice(23);
export const idStringToTime: Function = (id?: number) =>
  new Date(parseInt(id.slice(0, 13), 10)).toISOString().split('Z')[0] + id.slice(13) + 'Z';

export const dateToDayBegin: Function = (date: Date): Date => {
  date.setDate(date.getDate());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const dateToDayMid: Function = (date: Date): Date => {
  date.setDate(date.getDate());
  date.setHours(12);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};
export const dateToDayEnd: Function = (date: Date): Date => {
  date.setDate(date.getDate());
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(0);
  return date;
};

export const dateToRangeBegin: Function = (date: Date, range = 0): Date => {
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date = subHours(date, range);
  return date;
};

export const dateToRangeEnd: Function = (date: Date, range = 0): Date => {
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date = addHours(date, range + 1);
  return date;
};

export const dateFormatYearMonthDay: Function = (dateString: Date | string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  return formattedDate;
};

export const computeAgeDay: Function = (dateString: Date | string): number => {
  const birthDate = new Date(dateString);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const m = currentDate.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const computeWorkYear: Function = (
  dateStart: Date | string,
  dateEnd: Date | string
): number => {
  const newDateStart = new Date(dateStart);
  const newDateEnd = new Date(dateEnd);
  let year = newDateEnd.getFullYear() - newDateStart.getFullYear();
  const m = newDateEnd.getMonth() - newDateStart.getMonth();
  if (m < 0 || (m === 0 && newDateEnd.getDate() < newDateStart.getDate())) {
    year--;
  }
  return year;
};
