const PROVINCE = [
  {
    code: '11',
    name: '北京市',
    en: 'Beijing',
  },
  {
    code: '12',
    name: '天津市',
    en: 'Tianjin',
  },
  {
    code: '13',
    name: '河北省',
    en: 'Hebei',
  },
  {
    code: '14',
    name: '山西省',
    en: 'Shanxi',
  },
  {
    code: '15',
    name: '内蒙古自治区',
    en: 'NeiMengGu',
  },
  {
    code: '21',
    name: '辽宁省',
    en: 'Liaoning',
  },
  {
    code: '22',
    name: '吉林省',
    en: 'Jilin',
  },
  {
    code: '23',
    name: '黑龙江省',
    en: 'Heilongjiang',
  },
  {
    code: '31',
    name: '上海市',
    en: 'Shanghai',
  },
  {
    code: '32',
    name: '江苏省',
    en: 'Jiangsu',
  },
  {
    code: '33',
    name: '浙江省',
    en: 'Zhejiang',
  },
  {
    code: '34',
    name: '安徽省',
    en: 'Anhui',
  },
  {
    code: '35',
    name: '福建省',
    en: 'Fujian',
  },
  {
    code: '36',
    name: '江西省',
    en: 'Jiangxi',
  },
  {
    code: '37',
    name: '山东省',
    en: 'Shandong',
  },
  {
    code: '41',
    name: '河南省',
    en: 'Henan',
  },
  {
    code: '42',
    name: '湖北省',
    en: 'Hubei',
  },
  {
    code: '43',
    name: '湖南省',
    en: 'Hunan',
  },
  {
    code: '44',
    name: '广东省',
    en: 'Guangdong',
  },
  {
    code: '45',
    name: '广西壮族自治区',
    en: 'Guangxi',
  },
  {
    code: '46',
    name: '海南省',
    en: 'Hainan',
  },
  {
    code: '50',
    name: '重庆市',
    en: 'Chongqing',
  },
  {
    code: '51',
    name: '四川省',
    en: 'Sichuan',
  },
  {
    code: '52',
    name: '贵州省',
    en: 'Guizhou',
  },
  {
    code: '53',
    name: '云南省',
    en: 'Yunnan',
  },
  {
    code: '54',
    name: '西藏自治区',
    en: 'Tibet',
  },
  {
    code: '61',
    name: '陕西省',
    en: 'Shaanxi',
  },
  {
    code: '62',
    name: '甘肃省',
    en: 'Gansu',
  },
  {
    code: '63',
    name: '青海省',
    en: 'Qinghai',
  },
  {
    code: '64',
    name: '宁夏回族自治区',
    en: 'Ningxia',
  },
  {
    code: '65',
    name: '新疆维吾尔自治区',
    en: 'Xinjiang',
  },
  {
    code: '71',
    name: '台湾省',
    en: 'Taiwan',
  },
  {
    code: '81',
    name: '香港特别行政区',
    en: 'Hong Kong',
  },
  {
    code: '82',
    name: '澳门特别行政区',
    en: 'Aomen',
  },
];

export default PROVINCE;
