import imageCompression from 'browser-image-compression';

import CITY from './city';
import cityChild from './cityChild';
import PROVINCE from './province';

interface MatchAreaParamProps {
  /** 省份code */
  pCode: string;
  /** 城市code */
  cCode: string;
  /** 区县code */
  aCode: string;
}
export const MatchArea = (param: MatchAreaParamProps): string => {
  const { pCode, cCode, aCode } = param;
  const filterProvince = PROVINCE.filter(province => province.code === pCode);
  const filterCity = CITY.filter(city => city.code === cCode);
  const filterArea = cityChild.filter(area => area.code === aCode);
  if (pCode) {
    return `${filterProvince[0].name} ${filterCity[0].name} ${filterArea[0].name}`;
  } else {
    return `${filterCity[0].name} ${filterArea[0].name}`;
  }
};

export const CompressFile = async (fileToCompress: File): Promise<string> => {
  const options = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  let compressedFile: File;
  try {
    compressedFile = await imageCompression(fileToCompress, options);
    // debug(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
  } catch (error) {
    console.error('Compress file', error);
  }
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = (): void => resolve(reader.result as string);
    reader.readAsDataURL(compressedFile);
  });
};

export const birthdayComputed = (day: Date): string => {
  const currentDate = new Date();
  const birthYear = day.getFullYear();
  const birthMonth = day.getMonth();
  const birthDay = day.getDate();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  let age = currentYear - birthYear;
  if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
    age--;
  }
  return `${age}岁`;
};
