import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, StyleRules, Theme } from '@material-ui/core';
import { Link } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { getApiVersion } from '../../actions/versionActions';
import logo from '../../images/fintegrity-logo.png';
import GH from '../../images/gongan-beian.png';
import { AppState } from '../../reducers';
import { routerControlSetAction } from '../../reducers/routerControlReducer';
// import styles from './styles';

const footerStyles = (theme: Theme): StyleRules =>
  createStyles({
    footer: {
      color: 'rgba(255, 255, 255,0.8)',
      padding: '10px',
      display: 'flex',
      flexWrap: 'wrap',
      lineHeight: '1.5em',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        with: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: '1286px',
      },
    },
    contentOne: {
      display: 'flex',
      flexWrap: 'wrap',
      letterSpacing: '2px',
      [theme.breakpoints.up('md')]: {
        width: '1286px',
        justifyContent: 'space-between',
      },
    },
    gzhImgSmall: {
      width: '80px',
      height: '80px',
      // margin: '1rem 2rem 0.3rem 2rem',
      '&:hover ~$gzhImg': {
        display: 'block',
      },
    },
    gzhImg: {
      position: 'absolute',
      top: '-100px',
      display: 'none',
    },
    a: {
      textDecoration: 'none',
      backgroundColor: 'transparent',
      color: 'rgba(255, 255, 255,0.8)',
      fontSize: '0.85rem',
    },
    itemFontSize: {
      textAlign: 'right',
      fontSize: '0.85rem',
    },
    list: {
      marginBottom: '0',
      padding: '0',
      marginTop: '0',
    },
    inlineBlock: {
      display: 'inline-block',
      padding: '0px',
      width: 'auto',
    },
    footerSingleLine: {
      color: 'rgba(255, 255, 255,0.8)',
      height: '2rem',
      marginTop: '1rem',
      fontSize: '0.8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    oneTitle: {
      margin: '1rem 0',
      fontWeight: 'bold',
    },
    img: {
      width: '1rem',
      margin: 0,
    },
  });

const Footer = ({ classes }: WithStyles<typeof footerStyles>): ReactElement => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handleZN = (): void => {
    i18n.changeLanguage('zhCN');
  };
  const handleEN = (): void => {
    i18n.changeLanguage('enUS');
  };
  const { urlHistory } = useSelector((appState: AppState) => appState.routerControlReducer);
  const { appVersion, apiVersion } = useSelector((appState: AppState) => appState.version);
  if (appVersion) console.log('App version', appVersion);
  if (apiVersion) console.log('Api version', apiVersion);

  useEffect(() => {
    if (!apiVersion) dispatch(getApiVersion());
  }, [apiVersion]);

  useEffect(() => {
    if (urlHistory && urlHistory?.length > 0) {
      if (window.location.pathname === '/') {
        dispatch(routerControlSetAction({ urlHistory: [] }));
      }
      if (
        urlHistory[urlHistory.length - 1] === window.location.pathname &&
        urlHistory[urlHistory.length - 2] === window.location.pathname
      ) {
        dispatch(routerControlSetAction({ urlHistory: [] }));
        navigate('/');
      }
    }
  }, [urlHistory]);

  useEffect(() => {
    dispatch(routerControlSetAction({ urlHistory: [...urlHistory, window.location.pathname] }));
  }, []);

  return (
    <footer style={{ width: '100%', background: '#505152' }}>
      <div className={classes.footer}>
        <div className={classes.contentOne}>
          <div style={{ width: '150px' }}>
            <div className={classes.oneTitle}>傅通</div>
            <div style={{ fontSize: '0.85rem' }}>
              <a href="/" className={classes.a} rel="noopener noreferrer">
                首页
              </a>
            </div>
            <div style={{ fontSize: '0.85rem' }}>
              <a
                href="https://about.fintegrity.cn/"
                className={classes.a}
                rel="noopener noreferrer"
              >
                关于傅通
              </a>
            </div>
            <div style={{ fontSize: '0.85rem' }}>
              <div
                className={classes.a}
                aria-haspopup="true"
                onClick={handleClick}
                style={{
                  color: 'rgba(255, 255, 255,0.8)',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <span>Language</span>
                <LanguageIcon fontSize="small"></LanguageIcon>
              </div>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.block}
              >
                <MenuItem onClick={handleZN}>中文🇨🇳</MenuItem>
                <MenuItem onClick={handleEN}>English🇬🇧</MenuItem>
              </Menu>
            </div>
          </div>
          <div style={{ width: '150px' }}>
            <div className={classes.oneTitle}>企业服务</div>
            <div>
              <a
                href="https://about.fintegrity.cn/ourservices"
                target="_blank"
                className={classes.a}
                rel="noopener noreferrer"
              >
                用人服务
              </a>
            </div>
            <div>
              <a
                href="https://about.fintegrity.cn/ourservices"
                target="_blank"
                className={classes.a}
                rel="noopener noreferrer"
              >
                用人招聘
              </a>
            </div>
            <div>
              <a
                href="https://about.fintegrity.cn/ourservices"
                target="_blank"
                className={classes.a}
                rel="noopener noreferrer"
              >
                灵活结算
              </a>
            </div>
          </div>
          <div style={{ width: '150px' }}>
            <div className={classes.oneTitle}>使用帮助</div>
            <div>
              <a
                href="https://about.fintegrity.cn/ourservices"
                target="_blank"
                className={classes.a}
                rel="noopener noreferrer"
              >
                用户协议
              </a>
            </div>
            <div>
              <a
                href="https://www.fintegrity.cn/privacy"
                target="_blank"
                className={classes.a}
                rel="noopener noreferrer"
              >
                查看隐私政策
              </a>
            </div>
          </div>
          <div style={{ width: '350px' }}>
            <div className={classes.oneTitle} style={{ textAlign: 'right' }}>
              <Link href="/" rel="noopener noreferrer">
                Fintegrity 傅通自业(测试)&copy;
                {new Date().getFullYear()}
                <img src={logo} alt="logo" className={classes.img} />
              </Link>
            </div>
            <div className={classes.itemFontSize}>工作日 10:00 - 18:00</div>
            <div className={classes.itemFontSize}>联系电话 17317519319</div>
            <div className={classes.itemFontSize}>邮箱 contact@fintegrity.cn</div>
          </div>
          <div
            style={{
              width: '100px',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <StaticImage
              src="../../images/ft_gzh.jpg"
              alt="A dinosaur"
              className={classes.gzhImgSmall}
            />
            <br />
            公众号
            {/* <StaticImage
              src="../../images/ft_gzh.jpg"
              alt="A dinosaur"
              width={100}
              height={100}
              className={classes.gzhImg}
            /> */}
          </div>
        </div>
        <div className={classes.footerSingleLine}>
          测试版若遇技术问题请联系我们
          <span style={{ margin: '0 1rem' }}></span>
          <img src={GH} alt="beiAn" style={{ margin: '0 0.5rem' }} />
          <span>沪公网安备 31010602006385号 | 沪ICP备18008471号-1</span>
        </div>
      </div>
    </footer>
  );
};

export default withStyles(footerStyles)(Footer);
