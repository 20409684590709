import { Option } from './industry';
export const industryFilterHandle = (industryList: [], Industry: Option[]) => {
  // 父级
  const industryParentList: [] = [];

  for (const z of industryList) {
    industryParentList.push(z[0]);
  }
  const newIndustryParentList = [...new Set(industryParentList)];
  // 二级
  const industrySecondList: [] = [];
  for (const z of industryList) {
    industrySecondList.push(z[1]);
  }
  const newIndustrySecondList = [...new Set(industrySecondList)];

  const industryParentFilter = Industry.filter(item => {
    for (let i = 0; i < newIndustryParentList.length; i++) {
      if (item.value == newIndustryParentList[i]) return true;
    }
  });
  const jsonIndustryParentFilter = JSON.stringify(industryParentFilter);
  const newIndustryParentFilter = JSON.parse(jsonIndustryParentFilter);
  for (const industryItem of newIndustryParentFilter) {
    industryItem.children = industryItem.children.filter((item: Option) => {
      for (let i = 0; i < newIndustrySecondList.length; i++) {
        if (item.value == newIndustrySecondList[i]) return true;
      }
    });
  }
  // 三级
  const industryThirdList: [] = [];

  for (const z of industryList) {
    industryThirdList.push(z[2]);
  }

  const industryLabel: [] = [];
  for (const industryItem of newIndustryParentFilter) {
    for (const industryChildrenItem of industryItem.children) {
      for (let i = 0; i < industryThirdList.length; i++) {
        industryChildrenItem.children.find((c: Option) => c.value == industryThirdList[i])?.label
          ? industryLabel.push(
              industryChildrenItem.children.find(c => c.value == industryThirdList[i])?.label
            )
          : '';
      }
    }
  }

  return industryLabel;
};
