import React, { ReactElement, useEffect, useState } from 'react';
import { Control, Controller, useForm, useFormState } from 'react-hook-form';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  StyleRules,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import i18n from 'i18next';

import { saveConsultantVerification } from '../../actions/consultant/consultantVerificationActions';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import COUNTRY from '../../utils/country';
import PROVINCE from '../../utils/province';
import { createConsultantSettingsInfo } from '../../views/consultant/consultant.model';
import { GeneralDialog } from '../Dialog';
const styles = (): StyleRules =>
  createStyles({
    helperText: {
      color: 'red',
    },
    submit: {
      textAlign: 'center',
    },
    AvatarBorder: {
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    buttonHeight: {
      margin: 'auto 0',
      height: '36.5px',
    },
    avatarColor: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    contentTop: {
      paddingTop: '20px',
    },
  });
const ResumePersonInfoView = ({ classes, onClose }: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const verificationId = consultant?.verification?.id;
  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      settings: createConsultantSettingsInfo(),
    },
    mode: 'onChange',
  });
  const { isDirty, errors, isValid } = useFormState({ control });

  const { t } = useTranslation();
  i18n.loadNamespaces('SetingsView');

  const provinceList: Array<{ code: string; name: string; en: string }> = PROVINCE;

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const savaInfo = async () => {
    if (isValid) {
      const settingsData = JSON.stringify(getValues('settings'));
      const settingsNewData = JSON.parse(settingsData);
      settingsNewData.workScenario = JSON.stringify(settingsNewData.workScenario);
      dispatch(
        saveConsultantVerification({
          id: verificationId,
          consultantType: 'PERSONAL',
          settings: settingsNewData,
          status: 'SETTINGS_REGISTERED',
        })
      );

      setIsDialogOpen(false);
      onClose && onClose();
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('数据格式不正确'),
        })
      );
    }
  };
  useEffect(() => {
    if (i18n.language == 'zhCN') {
      COUNTRY.sort((a, b) => a.name.localeCompare(b.name));
      provinceList.sort((a, b) => a.name.localeCompare(b.name));
    } else if (i18n.language == 'enUS') {
      COUNTRY.sort((a, b) => a.value.localeCompare(b.value));
      provinceList.sort((a, b) => a.en.localeCompare(b.en));
    }
  }, [i18n.language]);

  useEffect(() => {
    if (consultant.verification?.settings) {
      setValue('settings', consultant.verification?.settings);
    }
  }, [consultant.verification?.settings]);
  return (
    <>
      <Grid item container xs={12} className={classes.contentTop}>
        <Grid container item xs={12} className={classes.mgtop} justifyContent="space-between">
          <Grid container item xs={5}>
            <Grid item>
              <Controller
                render={({ field }) => (
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    autoFocus={false}
                    label="期望费用/小时"
                    error={!!errors?.settings?.expectedFeePerHour}
                    helperText={
                      errors?.settings?.expectedFeePerHour &&
                      t('settings_input_view:hour_fee_not_empty')
                    }
                    {...field}
                  />
                )}
                name={`settings.expectedFeePerHour`}
                control={control}
              ></Controller>
            </Grid>
            <Grid item className={classes.moneyHeight}>
              {/* ￥ */}
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <Grid item>
              <Controller
                render={({ field }) => (
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    autoFocus={false}
                    label="期望费用/月"
                    error={!!errors?.settings?.expectedFeePerMonth}
                    helperText={
                      errors?.settings?.expectedFeePerMonth &&
                      t('settings_input_view:m0nth_fee_not_empty')
                    }
                    {...field}
                  />
                )}
                name={`settings.expectedFeePerMonth`}
                control={control}
              ></Controller>
            </Grid>
            <Grid item className={classes.moneyHeight}>
              {/* ￥ */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.mgtop}
          justifyContent="space-between"
          style={{ margin: '1rem 0' }}
        >
          <Grid item xs={5}>
            <Controller
              render={({ field }) => (
                <FormControl variant="outlined" error={!!errors?.settings?.findingJob} fullWidth>
                  <FormLabel component="legend">
                    {t('settings_input_view:finding_projects')}
                  </FormLabel>
                  <RadioGroup aria-label="findingJob" {...field}>
                    <FormControlLabel
                      value="FindingJob"
                      control={<Radio />}
                      label={t('settings_input_view:finding_job')}
                    />
                    <FormControlLabel
                      value="notFindingJob"
                      control={<Radio />}
                      label={t('settings_input_view:not_finding_job')}
                    />
                  </RadioGroup>
                  {errors?.settings?.findingJob && (
                    <FormHelperText>{t('settings_input_view:finding_not_empty')}</FormHelperText>
                  )}
                </FormControl>
              )}
              name={`settings.findingJob`}
              control={control}
              rules={{ required: true }}
            ></Controller>
          </Grid>
          <Grid item xs={5}>
            <FormControl variant="outlined" fullWidth>
              <FormLabel component="legend">{t('settings_input_view:work_scenario')}</FormLabel>
              <FormGroup>
                <Controller
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} name="onsite" />}
                      label={t('settings_input_view:onsite')}
                    />
                  )}
                  name={`settings.workScenario.onsite`}
                  control={control}
                ></Controller>
                <Controller
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} name="remote" />}
                      label={t('settings_input_view:remote')}
                    />
                  )}
                  name={`settings.workScenario.remote`}
                  control={control}
                ></Controller>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item xs={12} className={classes.mgtop} justifyContent="space-between">
          <Grid item xs={5}>
            <Controller
              render={({ field }) => (
                <FormControl variant="outlined" error={!!errors?.settings?.workType} fullWidth>
                  <FormLabel component="legend">{t('settings_input_view:working_mode')}</FormLabel>
                  <RadioGroup {...field} aria-label="quiz">
                    <FormControlLabel
                      value="FullTimeJob"
                      control={<Radio />}
                      label={t('settings_input_view:fulltime_job')}
                    />
                    <FormControlLabel
                      value="notFullTimeJob"
                      control={<Radio />}
                      label={t('settings_input_view:not_fulltime_job')}
                    />
                  </RadioGroup>
                  {errors?.settings?.workType && (
                    <FormHelperText>
                      {t('settings_input_view:working_mode_not_empty')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              rules={{ required: true }}
              name={`settings.workType`}
              control={control}
            ></Controller>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.buttonContent}>
        <Button
          color="primary"
          onClick={() => {
            onClose && onClose();
          }}
        >
          取消
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (isDirty) {
              trigger('settings');
              setIsDialogOpen(true);
            }
          }}
        >
          确认修改
        </Button>
      </div>

      <GeneralDialog
        isOpen={isDialogOpen}
        titleText="修改信息"
        contentText="确认要修改这些信息吗？"
        handleEdit={(): void => {
          savaInfo();
        }}
        handleDialogClose={(): void => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};
export type ConsultantBasicInfoControl = Control<{ basicInfo: ConsultantBasicInfo }>;
export type ConsultantBasicInfoSetValue = UseFormSetValue<{ basicInfo: ConsultantBasicInfo }>;
export type ConsultantBasicInfoGetValue = UseFormGetValues<{ basicInfo: ConsultantBasicInfo }>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
}

export default withStyles(styles)(ResumePersonInfoView);
