import { gql } from '@apollo/client';

const GET_CONSULTANT_COMMENT = gql`
  query QUERY($consultantId: Long!) {
    adminGetConsultantAdminCommentList(consultantId: $consultantId) {
      id
      comment
      createTime
      admin {
        id
        user {
          email
          phone
          status
          id
        }
      }
    }
  }
`;

const ADD_CONSULTANT_COMMENT = gql`
  mutation MUTATION($consultantId: Long!, $Comment: String!) {
    adminAddConsultantAdminComment(consultantId: $consultantId, comment: $Comment) {
      id
      comment
      createTime
      admin {
        id
        user {
          email
          phone
          status
          id
        }
      }
    }
  }
`;

const DELETE_CONSULTANT_COMMENT = gql`
  mutation MUTATION($commentId: Long!) {
    adminDeleteConsultantAdminComment(commentId: $commentId)
  }
`;

export { GET_CONSULTANT_COMMENT, ADD_CONSULTANT_COMMENT, DELETE_CONSULTANT_COMMENT };
