import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  FormControl,
  Grid,
  StyleRules,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18n from 'i18next';

import { saveConsultantVerification } from '../../actions/consultant/consultantVerificationActions';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import { createConsultantPersonalHonors } from '../../views/consultant/consultant.model';
import { honorEditContentType } from '../../views/consultant/ResumeView';
import { GeneralDialog } from '../Dialog';

const styles = (): StyleRules =>
  createStyles({
    borders: {
      position: 'relative',
      verticalAlign: 'middle',
      border: '1px solid #a2a294',
    },
    helperText: {
      color: 'red',
    },
    submit: {
      textAlign: 'center',
    },
    AvatarBorder: {
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    buttonHeight: {
      margin: 'auto 0',
      height: '36.5px',
    },
    avatarColor: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      margin: '20px 0',
    },
    contentTop: {
      paddingBottom: '20px',
    },
    cardWidth: {
      width: '100%',
      height: '40px',
      paddingTop: '8px',
    },
  });
const HonorView = ({ classes, onClose, editStatus, editContent }: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const verificationId = consultant?.verification?.id;
  const { t } = useTranslation();
  i18n.loadNamespaces('PersonalHonors');

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      personalHonorList: createConsultantPersonalHonors(),
    },
    mode: 'onChange',
  });

  const { isDirty, isValid } = useFormState({ control });

  const savaInfo = async () => {
    if (isValid) {
      if (editStatus == 'add') {
        const personalHonorListData = [
          ...JSON.parse(JSON.stringify(consultant.verification?.honorInfoList)),
          getValues('personalHonorList'),
        ];
        for (let i = 0; i < personalHonorListData.length; i++) {
          if (
            personalHonorListData[i].honorDateRangeStart &&
            typeof personalHonorListData[i].honorDateRangeStart == 'string'
          ) {
            personalHonorListData[i].honorDateRangeStart = new Date(
              personalHonorListData[i].honorDateRangeStart
            );
          }
          if (
            personalHonorListData[i].honorDateRangeEnd &&
            typeof personalHonorListData[i].honorDateRangeEnd == 'string'
          ) {
            personalHonorListData[i].honorDateRangeEnd = new Date(
              personalHonorListData[i].honorDateRangeEnd
            );
          }
        }
        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            honorInfoList: personalHonorListData,
            status: 'LANGUAGE_REGISTERED',
          })
        );
      }
      if (editStatus == 'edit') {
        const saveEditData = JSON.stringify(consultant.verification?.honorInfoList);
        const personalHonorListData = JSON.parse(saveEditData);
        if (editContent?.index != undefined && editContent?.index != null) {
          personalHonorListData[editContent.index] = getValues('personalHonorList');

          for (let i = 0; i < personalHonorListData.length; i++) {
            if (
              personalHonorListData[i].honorDateRangeStart &&
              typeof personalHonorListData[i].honorDateRangeStart == 'string'
            ) {
              personalHonorListData[i].honorDateRangeStart = new Date(
                personalHonorListData[i].honorDateRangeStart
              );
            }
            if (
              personalHonorListData[i].honorDateRangeEnd &&
              typeof personalHonorListData[i].honorDateRangeEnd == 'string'
            ) {
              personalHonorListData[i].honorDateRangeEnd = new Date(
                personalHonorListData[i].honorDateRangeEnd
              );
            }
          }
          dispatch(
            saveConsultantVerification({
              id: verificationId,
              consultantType: 'PERSONAL',
              honorInfoList: personalHonorListData,
              status: 'LANGUAGE_REGISTERED',
            })
          );
        }
      }

      setIsDialogOpen(false);
      onClose && onClose();
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('数据格式不正确'),
        })
      );
    }
  };

  useEffect(() => {
    if (editContent && editStatus == 'edit') {
      setValue('personalHonorList', editContent.honorEditContent);
    }
  }, [editContent]);

  return (
    <>
      <Grid item container xs={12} className={classes.contentTop}></Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                autoFocus={false}
                label={t('personal_honors:personal_honor')}
                // error={!!errors?.personalHonorList?.[index]?.honorTitle}
                // helperText={errors?.personalHonorList?.[index]?.honorTitle && '个人荣誉不能为空'}
                {...field}
              />
            )}
            name={`personalHonorList.honorTitle`}
            // rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  // error={!!errors?.personalHonorList?.[index]?.startTime}
                  fullWidth
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      size="small"
                      variant="inline"
                      format="yyyy-MM"
                      views={['year', 'month']}
                      id="date-picker-start"
                      label={t('personal_honors:start_time')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      {...field}
                      value={field.value ?? null}
                    />
                  </MuiPickersUtilsProvider>
                  {/* {errors?.personalHonorList?.[index]?.startTime && (
                    <FormHelperText>开始时间不能为空</FormHelperText>
                  )} */}
                </FormControl>
              );
            }}
            name={`personalHonorList.honorDateRangeStart`}
            // rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl margin="normal" variant="outlined" fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    size="small"
                    variant="inline"
                    format="yyyy-MM"
                    views={['year', 'month']}
                    id="date-picker-end"
                    label={t('personal_honors:end_time')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    {...field}
                    value={field.value ?? null}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            )}
            name={`personalHonorList.honorDateRangeEnd`}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ field }) => (
            <TextField
              multiline
              size="small"
              minRows={4}
              fullWidth
              variant="outlined"
              autoFocus={false}
              label={t('personal_honors:honors_introduction')}
              // error={!!errors?.personalHonorList?.[index]?.content}
              // helperText={errors?.personalHonorList?.[index]?.content && '荣誉简介不能为空'}
              {...field}
            />
          )}
          name={`personalHonorList.honorContent`}
          control={control}
          // rules={{ required: true }}
        ></Controller>
      </Grid>
      <div className={classes.buttonContent}>
        <Button
          color="primary"
          onClick={() => {
            onClose && onClose();
          }}
        >
          取消
        </Button>
        {editStatus === 'edit' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('personalHonorList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认修改
          </Button>
        )}
        {editStatus === 'add' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('personalHonorList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认添加
          </Button>
        )}
      </div>
      <GeneralDialog
        isOpen={isDialogOpen}
        titleText="修改信息"
        contentText="确认要修改这些信息吗？"
        handleEdit={(): void => {
          savaInfo();
        }}
        handleDialogClose={(): void => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  editStatus?: string;
  editContent?: honorEditContentType;
}

export default withStyles(styles)(HonorView);
