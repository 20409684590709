/**
 * 初始化基本信息
 *
 * @export
 * @return {*}  {ConsultantBasicInfo}
 */
export const createConsultantBasicInfo = (): ConsultantBasicInfo => ({
  // 头像
  avatar: '',
  // 国籍
  nationality: '',
  // 地区(省/市)
  regionProvince: '',
  // 地区(省/市)
  regionCity: '',
  // 姓氏
  firstName: '',
  // 名
  lastName: '',
  // 手机号码
  phoneNumber: '',
  // 性别
  gender: '',
  // 出生年月
  birthday: undefined,
  // 出生年月
  birthYear: 0,
  // 出生年月
  birthMonth: 0,
  // 证件类型
  passportType: '',
  // 证件号码
  passportId: '',
  // 联系邮箱
  contactEmail: '',
  // 开始工作时间
  // workStartDate: '',
  // 现居住地址(省/市)
  nowAddressProvince: '',
  // 现居住地址(省/市)
  nowAddressCity: '',
  // 区县
  nowAddressDistrict: '',
  // 详细地址
  nowAddressDetail: '',
  // 个人描述
  personalDescription: '',
  // 证件复印件说明(文件)
  personalDescriptionFileList: [],
});

/**
 * 初始化教育经历
 *
 * @export
 * @return {*}  {ConsultantEducationInfo}
 */
export const createConsultantEducationInfo = (): ConsultantEducationInfo => ({
  // 学历
  education: undefined,
  // 学校名称
  schoolName: undefined,
  // 专业分类
  majorType: undefined,
  // 专业方向
  academicDirections: undefined,
  // 在校时间
  atSchoolDateRangeStart: null,
  // 在校时间
  atSchoolDateRangeEnd: null,
  // 学历材料说明(文件)
  educationFileList: [],
});

/**
 * 初始化工作经历
 *
 * @export
 * @return {*}  {ConsultantWorkInfo}
 */
export const createConsultantWorkInfo = (): ConsultantWorkInfo => ({
  // 公司名称
  companyName: undefined,
  // 专业
  professionalClass: [],
  // 行业
  industryClass: [],
  // 工作方式
  wayOfWorking: 1,
  // 工作职位
  jobTitle: undefined,
  // 入职时间
  atWorkDateRangeStart: null,
  // 离职时间
  atWorkDateRangeEnd: null,
  // 工作内容
  jobDescription: undefined,
});

/**
 * 初始化项目经历
 *
 * @export
 * @return {*}  {ConsultantProjectInfo}
 */
export const createConsultantProjectInfo = (): ConsultantProjectInfo => ({
  // 项目名称
  projectName: undefined,
  // 所属公司
  projectCompanyName: undefined,
  // 开始时间
  projectDateStart: null,
  // 结束时间
  projectDateEnd: null,
  // 项目描述
  projectDescription: undefined,
  // 项目图片
  projectImageFileList: [],
});

/**
 * 初始化语言
 *
 * @export
 * @return {*}  {ConsultantLanguageInfo}
 */
export const createConsultantLanguageInfo = (): ConsultantLanguageInfo => ({
  // 语言名称
  languageName: '',
  // 语言级别
  languageLevel: '',
});

/**
 * 初始化设置
 *
 * @export
 * @return {*}  {ConsultantSettingsInfo}
 */
export const createConsultantSettingsInfo = (): ConsultantSettingsInfo => ({
  // 找项目
  findingJob: 'FindingJob',
  // 是否公开档案
  workDossier: 'Public',
  // 工作场景
  workScenario: {
    // 远程
    remote: false,
    // 现场
    onsite: false,
  },
  // 工作方式
  workType: 'FullTimeJob',
  expectedFeePerHour: undefined,
  // 顾问费率moon
  expectedFeePerMonth: undefined,
});

/**
 * 初始化荣誉
 *
 * @export
 * @return {*}  {ConsultantPersonalHonorInfo}
 */
// 个人荣誉
export const createConsultantPersonalHonors = (): ConsultantPersonalHonorInfo => ({
  // 标题
  honorTitle: '',
  // 开时间
  honorDateRangeStart: null,
  // 结束时间
  honorDateRangeEnd: null,
  // 内容
  honorContent: '',
});

/**
 * 初始化证书
 *
 * @export
 * @return {*}  {ConsultantCertificateInfo}
 */
// 个人证书
export const createConsultantCertificate = (): ConsultantCertificateInfo => ({
  // 证书名
  certificateName: '',
  // 证书类型
  certificateDescription: '',
  // 证书文件
  certificateFileList: [],
});

/**
 * 初始我的介绍
 *
 * @export
 * @return {*}  {ConsultantOtherInfo}
 */
export const createConsultantMyIntroduction = (): ConsultantOtherInfo => ({
  // 个人优势
  strength: '',
  // 个人背景
  background: '',
  // 个人描述
  description: '',
  // 简历
  resume: '',
});

/**
 * 初始化公司信息
 *
 * @export
 * @return {*}  {ConsultantCompanyInfo}
 */
export const createConsultantCompanyInfo = (): ConsultantCompanyInfo => ({
  // 公司信息
  companyName: '',
  // 联系人
  contacts: '',
  // 联系方式
  contactNumber: '',
  // 所属行业
  industry: '',
  // 公司地址
  companyAddressCity: '',
  // 公司地址
  companyAddressRegion: '',
  // 公司注册地址
  companyRegisteredAddress: '',
  // 实际工作地址
  companyRealAddress: '',
  // 公司简介
  companyDescription: '',
});

/**
 * 初始化公司资源信息
 *
 * @export
 * @return {*}  {ConsultantCompanyResourceInfo}
 */
export const createConsultantCompanyResourceInfo = (): ConsultantCompanyResourceInfo => ({
  // 行业分类
  industryType: '',
  // 岗位专业分类
  postTypeLv1: '',
  // 岗位专业分类
  postTypeLv2: '',
  // 职位类型&资源数量
  jobInfo: [createJobInfo()],
});

/**
 * 初始化公司详细信息
 *
 * @export
 * @return {*}  {ConsultantWorkComInfo}
 */
export const createWorkComInfo = (): ConsultantWorkComInfo => ({
  // 企业组织全称
  companyFullName: '',
  // 法定代表人名称
  ownerName: '',
  // 企业组织注册编号
  companyRegisteredId: '',
  // 法人身份证号
  ownerPassportId: '',
  // 社会团体上传登记证书
  companyEvidence: '',
  // 法人(运营者)身份证照片
  ownerEvidence: '',
});

/**
 * 初始化职位类型&资源数量
 *
 * @export
 * @return {*}  {ConsultantJobInfo}
 */
export const createJobInfo = (): ConsultantJobInfo => ({
  // 职位类型
  jobType: '',
  // 资源数量
  resourceNumber: '',
});

// export type BasicInfoSelectProvince = {
//   // 省份id
//   selectRegion: string | null;
//   // 现居省份
//   selectNowAddress: string | null;
// };

export type City = {
  code: string;
  name: string;
  enName?: string;
  provinceCode: string;
};

// export function createBasicInfoSelectProvince(): BasicInfoSelectProvince {
//   return {
//     selectRegion: null,
//     selectNowAddress: null,
//   };
// }

export interface Country {
  name: string;
  value: string;
  phoneCode: string;
}
