import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  Grid,
  StyleRules,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import i18n from 'i18next';

import { CertificateTypeArray, CertificateTypeArrayEn } from '../.././utils/CertificateType';
import { saveConsultantVerification } from '../../actions/consultant/consultantVerificationActions';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import { createConsultantCertificate } from '../../views/consultant/consultant.model';
import { certificateEditContentType } from '../../views/consultant/ResumeView';
import { GeneralDialog } from '../Dialog';

const styles = (): StyleRules =>
  createStyles({
    borders: {
      position: 'relative',
      verticalAlign: 'middle',
      border: '1px solid #a2a294',
    },
    helperText: {
      color: 'red',
    },
    submit: {
      textAlign: 'center',
    },
    AvatarBorder: {
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    buttonHeight: {
      margin: 'auto 0',
      height: '36.5px',
    },
    avatarColor: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      margin: '20px 0',
    },
    contentTop: {
      paddingBottom: '20px',
    },
    cardWidth: {
      width: '100%',
      height: '40px',
      paddingTop: '8px',
    },
  });
const CertificateView = ({ classes, onClose, editStatus, editContent }: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const verificationId = consultant?.verification?.id;
  const { t } = useTranslation();
  i18n.loadNamespaces('PersonalHonors');

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      consultantCertificateList: createConsultantCertificate(),
    },
    mode: 'onChange',
  });

  const { isDirty, isValid } = useFormState({ control });

  const savaInfo = async () => {
    if (isValid) {
      if (editStatus == 'add') {
        const consultantCertificateListData = [
          ...JSON.parse(JSON.stringify(consultant.verification?.certificateInfoList)),
          getValues('consultantCertificateList'),
        ];

        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            certificateInfoList: consultantCertificateListData,
            status: 'LANGUAGE_REGISTERED',
          })
        );
      }
      if (editStatus == 'edit') {
        const saveEditData = JSON.stringify(consultant.verification?.certificateInfoList);
        const consultantCertificateListData = JSON.parse(saveEditData);
        if (editContent?.index != undefined && editContent?.index != null) {
          consultantCertificateListData[editContent.index] = getValues('consultantCertificateList');

          dispatch(
            saveConsultantVerification({
              id: verificationId,
              consultantType: 'PERSONAL',
              certificateInfoList: consultantCertificateListData,
              status: 'LANGUAGE_REGISTERED',
            })
          );
        }
      }

      setIsDialogOpen(false);
      onClose && onClose();
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('数据格式不正确'),
        })
      );
    }
  };

  useEffect(() => {
    if (editContent && editStatus == 'edit') {
      setValue('consultantCertificateList', editContent.certificateEditContent);
    }
  }, [editContent]);

  return (
    <>
      <Grid item container xs={12} className={classes.contentTop}></Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                autoFocus={false}
                label={t('certificate_input_view:certificate_name')}
                // error={!!errors?.personalHonorList?.[index]?.title}
                // helperText={errors?.personalHonorList?.[index]?.title && '个人荣誉不能为空'}
                {...field}
              />
            )}
            name={`consultantCertificateList.certificateName`}
            // rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <Autocomplete
                defaultValue={null}
                size="small"
                id="combo-box-demo"
                options={i18n.language == 'enUS' ? CertificateTypeArrayEn : CertificateTypeArray}
                getOptionLabel={option => option.label}
                getOptionDisabled={option => option.disabled == true}
                fullWidth
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      label={t('certificate_input_view:certificate_type')}
                      variant="outlined"
                    />
                  );
                }}
                value={
                  (i18n.language == 'enUS' ? CertificateTypeArrayEn : CertificateTypeArray).find(
                    option => option.value === field.value
                  ) || null
                } // 设置 Autocomplete 的 value 属性
                onChange={(_, newValue) => {
                  field.onChange(newValue?.value || null); // 更新 React Hook Form 字段值
                }}
              />
            )}
            name={`consultantCertificateList.certificateDescription`}
            control={control}
            // rules={{ required: true }}
          ></Controller>
        </Grid>
      </Grid>
      <div className={classes.buttonContent}>
        <Button
          color="primary"
          onClick={() => {
            onClose && onClose();
          }}
        >
          取消
        </Button>
        {editStatus === 'edit' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('consultantCertificateList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认修改
          </Button>
        )}
        {editStatus === 'add' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('consultantCertificateList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认添加
          </Button>
        )}
      </div>
      <GeneralDialog
        isOpen={isDialogOpen}
        titleText="修改信息"
        contentText="确认要修改这些信息吗？"
        handleEdit={(): void => {
          savaInfo();
        }}
        handleDialogClose={(): void => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  editStatus?: string;
  editContent?: certificateEditContentType;
}

export default withStyles(styles)(CertificateView);
