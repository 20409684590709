import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Switch,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
// import AddIcon from '@material-ui/icons/Add';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';

import {
  getConsultantVerification,
  saveConsultantVerification,
} from '../../actions/consultant/consultantVerificationActions';
import { GeneralDialog } from '../../components/Dialog';
import PersonalAccountNavigation from '../../components/PersonalAccountNavigation';
import CertificateView from '../../components/ResumeInputView/CertificateView';
import EducationView from '../../components/ResumeInputView/educationView';
import HonorView from '../../components/ResumeInputView/HonorView';
import IntroduceView from '../../components/ResumeInputView/IntroduceView';
import LanguageView from '../../components/ResumeInputView/LanguageView';
import OtherSettingView from '../../components/ResumeInputView/OtherSettingView';
import PersonInfoView from '../../components/ResumeInputView/PersonInfoView';
import ProjectExperiencesView from '../../components/ResumeInputView/ProjectExperiencesView';
import WorkInfoView from '../../components/ResumeInputView/WorkInfoView';
import { AppState } from '../../reducers';
import CertificateTypeArray from '../../utils/CertificateType';
import CITY from '../../utils/city';
import cityChild from '../../utils/cityChild';
import { dateFormatYearMonthDay } from '../../utils/converter';
import educationBackground from '../../utils/educationBackground';
import Industry from '../../utils/industry';
import { industryFilterHandle } from '../../utils/industryFilter';
import LANGUAGE_WORLD from '../../utils/languageType';
import { searchLabelsByValue } from '../../utils/searchUtils';
import Professional from '../../utils/special';
import specialized from '../../utils/specialized';
import { birthdayComputed } from '../../utils/util';

const styles = createStyles({
  currentText: {
    wordBreak: 'break-word',
    position: 'relative',
    padding: '1rem 0 1rem 2rem',
    borderRadius: '5px',
    // '&:hover': {
    //   backgroundColor: '#f5f6fa',
    // },
  },
  root: {
    minHeight: '100vh',
    width: '1286px',
    display: 'flex',
    margin: '0 auto',
    padding: '0',
  },
  left: {
    padding: '2rem 0',
    width: '256px',
  },
  right: {
    flex: '1',
    padding: '2rem',
  },
  rightCard: {
    padding: '0 2rem',
  },
  textContent: {
    position: 'relative',
    whiteSpace: 'normal',
  },
  exportResume: {
    textAlign: 'right',
    cursor: 'pointer',
  },
  leftMargin: {
    marginLeft: '1rem',
    color: '#9b9bbe',
    '&:hover': {
      color: '#595959',
    },
  },
  attachment: {
    width: '180px',
    // padding: '2rem 1rem',
  },
  contentBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textAttachment: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },

  privacySetting: {
    marginTop: '3rem',
  },
  textFont: {
    fontSize: '0.875rem',
  },
  attachmentCard: {
    marginTop: '2rem',
    padding: '1rem',
  },
  titleGrid: {
    margin: '0',
    paddingTop: '64px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '5rem auto',
  },
  titleSize: {
    gridColumn: '1',
    margin: '0',
  },
  fontEdit: {
    position: 'absolute',
    top: '0',
    right: '3px',
    justifySelf: 'end',
    cursor: 'pointer',
    color: '#9b9bbe',
    fontSize: '0.875rem',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: '#4c0099',
    },
  },
  fontDelete: {
    position: 'absolute',
    bottom: '0',
    right: '3px',
    justifySelf: 'end',
    cursor: 'pointer',
    color: '#9b9bbe',
    fontSize: '0.875rem',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: '#4c0099',
    },
  },
  fontAddContent: {
    marginRight: '5px',
    display: 'flex',
    alignItems: 'center',
    gridColumn: '2',
    justifySelf: 'end',
    cursor: 'pointer',
    color: '#9b9bbe',
    fontSize: '0.875rem',
    '&:hover': {
      color: '#595959',
    },
  },
  detailText: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    margin: '1rem',
  },
  avatar: {
    borderRadius: '50%',
    width: '80px',
    height: '80px',
  },
  spanSpace: {
    marginLeft: '1rem',
    color: '#9b9bbe',
  },
  personal: {
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    wordWrap: 'break-word',
  },
  childrenItem: {
    padding: '5px',
    margin: '5px',
    position: 'relative',
    borderRadius: '5px',
    // border: '1px solid  #f0f0f5',
  },
  cssA: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

export const Proficiency_level = {
  Beginner: '初级',
  Intermediate: '中级',
  Advanced: '中级',
  Proficiency: '精通',
};

export interface workEditContentType {
  workEditContent: ConsultantWorkInfo;
  index: number;
}

export interface projectEditContentType {
  projectEditContent: ConsultantProjectInfo;
  index: number;
}

export interface educationEditContentType {
  educationEditContent: ConsultantEducationInfo;
  index: number;
}

export interface languageEditContentType {
  languageEditContent: ConsultantLanguageInfo;
  index: number;
}

export interface honorEditContentType {
  honorEditContent: ConsultantPersonalHonorInfo;
  index: number;
}

export interface certificateEditContentType {
  certificateEditContent: ConsultantCertificateInfo;
  index: number;
}

const ResumeView = ({ classes }: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const verificationId = consultant?.verification?.id;
  const [resumeIntroduceEdit, SetResumeIntroduceEdit] = useState(false);
  const [resumePersonEdit, SetResumePersonEdit] = useState(false);

  const [resumeWorkEdit, SetResumeWorkEdit] = useState(false);
  const [workEditStatus, setWorkEditStatus] = useState('');

  const [workEditContent, setWorkEditContent] = useState<workEditContentType>();
  const [workScenario, setWorkScenario] = useState<workScenarioSelect>();

  const [deleteItem, setDeleteItem] = useState<string>('');

  const [projectEditStatus, setProjectEditStatus] = useState('');
  const [resumeProject, setResumeProject] = useState<boolean>(false);
  const [projectEditContent, setProjectEditContent] = useState<projectEditContentType>();

  const [resumeEducation, setResumeEducation] = useState<boolean>(false);
  const [educationEditStatus, setEducationEditStatus] = useState('');
  const [educationEditContent, setEducationEditContent] = useState<educationEditContentType>();

  const [resumeLanguage, setResumeLanguage] = useState<boolean>(false);
  const [languageEditStatus, setLanguageEditStatus] = useState('');
  const [languageEditContent, setLanguageEditContent] = useState<languageEditContentType>();

  const [resumeHonor, setResumeHonor] = useState<boolean>(false);
  const [honorEditStatus, setHonorEditStatus] = useState('');
  const [honorEditContent, setHonorEditContent] = useState<honorEditContentType>();

  const [resumeCertificate, setResumeCertificate] = useState<boolean>(false);
  const [certificateEditStatus, setCertificateEditStatus] = useState('');
  const [certificateEditContent, setCertificateEditContent] = useState<
    certificateEditContentType
  >();

  const [resumeOtherSetting, setResumeOtherSetting] = useState<boolean>(false);

  const resultProfessional = (professionalList: string): string[] => {
    const data = JSON.parse(professionalList);
    const valuesToSearch = data.map((subArray: []) => subArray[subArray.length - 1]);
    const zhi = searchLabelsByValue(Professional, valuesToSearch);
    return zhi;
  };
  const resultIndustryView = (industry: string | CascadeArrayType[]): [] => {
    const industryObj = industry ? JSON.parse(industry.toString()) : '';
    if (industryObj) {
      const industry = industryFilterHandle(industryObj, Industry);
      return industry as [];
    }
    return [];
  };
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const workItemDelete = () => {
    const data = JSON.stringify(consultant.verification?.workInfoList);
    const workInfoList = JSON.parse(data);
    workInfoList.splice(workEditContent?.index, 1);
    for (let i = 0; i < workInfoList.length; i++) {
      if (
        workInfoList[i].atWorkDateRangeEnd &&
        typeof workInfoList[i].atWorkDateRangeEnd == 'string'
      ) {
        workInfoList[i].atWorkDateRangeEnd = new Date(workInfoList[i].atWorkDateRangeEnd);
      }
      if (
        workInfoList[i].atWorkDateRangeStart &&
        typeof workInfoList[i].atWorkDateRangeStart == 'string'
      ) {
        workInfoList[i].atWorkDateRangeStart = new Date(workInfoList[i].atWorkDateRangeStart);
      }
    }

    dispatch(
      saveConsultantVerification({
        id: verificationId,
        consultantType: 'PERSONAL',
        workInfoList: workInfoList,
        status: 'LANGUAGE_REGISTERED',
      })
    );
  };

  const projectItemDelete = () => {
    const data = JSON.stringify(consultant.verification?.projectInfoList);
    const projectInfoList = JSON.parse(data);
    projectInfoList.splice(projectEditContent?.index, 1);
    for (let i = 0; i < projectInfoList.length; i++) {
      if (
        projectInfoList[i].projectDateStart &&
        typeof projectInfoList[i].projectDateStart == 'string'
      ) {
        projectInfoList[i].projectDateStart = new Date(projectInfoList[i].projectDateStart);
      }
      if (
        projectInfoList[i].projectDateEnd &&
        typeof projectInfoList[i].projectDateEnd == 'string'
      ) {
        projectInfoList[i].projectDateEnd = new Date(projectInfoList[i].projectDateEnd);
      }
    }

    dispatch(
      saveConsultantVerification({
        id: verificationId,
        consultantType: 'PERSONAL',
        projectInfoList: projectInfoList,
        status: 'LANGUAGE_REGISTERED',
      })
    );
  };

  const educationItemDelete = () => {
    const data = JSON.stringify(consultant.verification?.educationInfoList);
    const InfoList = JSON.parse(data);
    InfoList.splice(projectEditContent?.index, 1);
    for (let i = 0; i < InfoList.length; i++) {
      if (
        InfoList[i].atSchoolDateRangeStart &&
        typeof InfoList[i].atSchoolDateRangeStart == 'string'
      ) {
        InfoList[i].atSchoolDateRangeStart = new Date(InfoList[i].atSchoolDateRangeStart);
      }
      if (InfoList[i].atSchoolDateRangeEnd && typeof InfoList[i].atSchoolDateRangeEnd == 'string') {
        InfoList[i].atSchoolDateRangeEnd = new Date(InfoList[i].atSchoolDateRangeEnd);
      }
    }
    dispatch(
      saveConsultantVerification({
        id: verificationId,
        consultantType: 'PERSONAL',
        educationInfoList: InfoList,
        status: 'LANGUAGE_REGISTERED',
      })
    );
  };

  const languageItemDelete = () => {
    const data = JSON.stringify(consultant.verification?.languageInfoList);
    const InfoList = JSON.parse(data);
    InfoList.splice(languageEditContent?.index, 1);
    dispatch(
      saveConsultantVerification({
        id: verificationId,
        consultantType: 'PERSONAL',
        languageInfoList: InfoList,
        status: 'LANGUAGE_REGISTERED',
      })
    );
  };

  const honorItemDelete = () => {
    const data = JSON.stringify(consultant.verification?.honorInfoList);
    const InfoList = JSON.parse(data);
    InfoList.splice(honorEditContent?.index, 1);
    for (let i = 0; i < InfoList.length; i++) {
      if (InfoList[i].honorDateRangeStart && typeof InfoList[i].honorDateRangeStart == 'string') {
        InfoList[i].honorDateRangeStart = new Date(InfoList[i].honorDateRangeStart);
      }
      if (InfoList[i].honorDateRangeEnd && typeof InfoList[i].honorDateRangeEnd == 'string') {
        InfoList[i].honorDateRangeEnd = new Date(InfoList[i].honorDateRangeEnd);
      }
    }
    dispatch(
      saveConsultantVerification({
        id: verificationId,
        consultantType: 'PERSONAL',
        honorInfoList: InfoList,
        status: 'LANGUAGE_REGISTERED',
      })
    );
  };

  const certificateDelete = () => {
    const data = JSON.stringify(consultant.verification?.certificateInfoList);
    const InfoList = JSON.parse(data);
    InfoList.splice(certificateEditContent?.index, 1);

    dispatch(
      saveConsultantVerification({
        id: verificationId,
        consultantType: 'PERSONAL',
        certificateInfoList: InfoList,
        status: 'LANGUAGE_REGISTERED',
      })
    );
  };

  const [open, setOpen] = React.useState(false);
  const [shareMessage, setShareMessage] = useState('');

  const copyToClipboard = async () => {
    const url = window.location.host + '/pdfPrint/#' + verificationId;
    // console.log('1', url, window.location);
    // return;
    try {
      if (navigator.clipboard) {
        await window.navigator.clipboard.writeText(url);
        setShareMessage(`已复制链接`);
        setOpen(true);
      } else {
        const el = document.createElement('textarea');
        el.value = url;

        document.body.appendChild(el);
        el.focus();
        el.select();

        try {
          const successful = document.execCommand('copy');
          successful ? setShareMessage(`已复制链接`) : setShareMessage(`无法复制链接！`);
          setOpen(true);
        } catch (err) {
          setShareMessage(`无法复制链接！`);
          setOpen(true);
        } finally {
          document.body.removeChild(el);
        }
      }
    } catch (err) {
      setShareMessage(`复制链接失败`);
      setOpen(true);
    }
  };

  useEffect(() => {
    if (consultant && consultant.id) dispatch(getConsultantVerification(consultant.id));
  }, [consultant.id]);

  useEffect(() => {
    if (consultant && consultant.verification && consultant.verification.basicInfo) {
      if (consultant.verification?.settings?.workScenario) {
        setWorkScenario(
          JSON.parse(JSON.stringify(consultant.verification?.settings?.workScenario))
        );
      }
    }
  }, [consultant.verification]);

  return (
    <div className={classes.root}>
      <Snackbar
        style={{ zIndex: 10000 }}
        open={open}
        autoHideDuration={5000}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
        >
          {shareMessage}
        </Alert>
      </Snackbar>
      <div className={classes.left}>
        <PersonalAccountNavigation> </PersonalAccountNavigation>
        <Card variant="outlined" style={{ marginTop: '20px' }}>
          <List component="nav" aria-label="main mailbox folders">
            <a href="#basicInfo" className={classes.cssA}>
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="基本信息" />
              </ListItem>
            </a>
            <a href="#self-introduction" className={classes.cssA}>
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="自我介绍" />
              </ListItem>
            </a>
            <a href="#work-management" className={classes.cssA}>
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="工作经历" />
              </ListItem>
            </a>
            <a href="#project-experience" className={classes.cssA}>
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="项目经历" />
              </ListItem>
            </a>
            <a href="#educational-experience" className={classes.cssA}>
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="教育经历" />
              </ListItem>
            </a>
            <a href="#language" className={classes.cssA}>
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="语言" />
              </ListItem>
            </a>
            <a href="#personal-honor" className={classes.cssA}>
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="个人荣誉" />
              </ListItem>
            </a>
            <a href="#my-certificate" className={classes.cssA}>
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="我的证书" />
              </ListItem>
            </a>
            <a href="#other-info" className={classes.cssA}>
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="其他" />
              </ListItem>
            </a>
          </List>
        </Card>
      </div>
      <div className={classes.right}>
        <Card variant="outlined" className={classes.rightCard}>
          <div className={classes.titleGrid}>
            <h3 id="basicInfo">简历</h3>
          </div>
          <div className={classes.textContent}>
            <span
              className={classes.fontEdit}
              onClick={() => {
                SetResumePersonEdit(!resumePersonEdit);
              }}
            >
              编辑
            </span>

            {resumePersonEdit && (
              <PersonInfoView
                onClose={() => {
                  SetResumePersonEdit(false);
                }}
              />
            )}
            {!resumePersonEdit && (
              <div className={classes.detailText}>
                <img
                  src={consultant.verification?.basicInfo?.avatar}
                  className={classes.avatar}
                  alt="avatar"
                />
                <div className={classes.currentText}>
                  <div className={classes.personal}>
                    {consultant.verification?.basicInfo?.lastName}
                    {consultant.verification?.basicInfo?.firstName}
                    <span className={classes.spanSpace}></span>
                    {consultant.verification?.basicInfo?.gender == 'male' ? '先生' : '女士'}
                    <span className={classes.spanSpace}></span>
                    {consultant.verification?.basicInfo?.birthday &&
                      birthdayComputed(new Date(consultant.verification?.basicInfo?.birthday))}
                  </div>

                  <div className={classes.personal}>
                    <MailOutlineIcon fontSize="small" />
                    {consultant.verification?.basicInfo?.contactEmail}
                    <span className={classes.spanSpace}></span>
                    <PhoneAndroidIcon fontSize="small" />
                    {consultant.verification?.basicInfo?.phoneNumber}
                  </div>
                  <div className={classes.personal}>
                    {/* {
                    PROVINCE.find(
                      c => c.code == consultant.verification?.basicInfo?.nowAddressProvince
                    )?.name
                  } */}
                    {
                      CITY.find(c => c.code == consultant.verification?.basicInfo?.nowAddressCity)
                        ?.name
                    }
                    <span className={classes.spanSpace}></span>
                    {
                      cityChild.find(
                        c => c.code === consultant?.verification?.basicInfo?.nowAddressDistrict
                      )?.name
                    }
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={classes.textContent} id="self-introduction">
            <div className={classes.titleGrid}>
              <h4 className={classes.titleSize}>自我介绍</h4>
            </div>
            <div className={classes.currentText}>
              <span
                className={classes.fontEdit}
                onClick={() => {
                  SetResumeIntroduceEdit(!resumeIntroduceEdit);
                }}
              >
                编辑
              </span>
              {resumeIntroduceEdit && (
                <IntroduceView
                  onClose={() => {
                    SetResumeIntroduceEdit(false);
                  }}
                />
              )}
              {!resumeIntroduceEdit && consultant.verification?.otherInfo?.description}
            </div>
          </div>

          <div className={classes.textContent} id="work-management">
            <div className={classes.titleGrid}>
              <h4 className={classes.titleSize}>工作经历</h4>
              <span
                className={classes.fontAddContent}
                onClick={() => {
                  SetResumeWorkEdit(false);
                  setTimeout(() => {
                    SetResumeWorkEdit(true);
                  }, 500);
                  setWorkEditStatus('add');
                }}
              >
                <AddBoxIcon fontSize="small" /> 添加
              </span>
            </div>
            <div className={classes.currentText}>
              {resumeWorkEdit && (
                <WorkInfoView
                  workEditContent={workEditContent}
                  editStatus={workEditStatus}
                  onClose={() => {
                    SetResumeWorkEdit(false);
                  }}
                />
              )}
              {consultant.verification?.workInfoList?.map((item, index) => {
                return (
                  <div key={index}>
                    <div className={classes.childrenItem}>
                      <span
                        className={classes.fontEdit}
                        onClick={() => {
                          SetResumeWorkEdit(false);
                          setTimeout(() => {
                            SetResumeWorkEdit(true);
                          }, 500);
                          setWorkEditStatus('edit');
                          setWorkEditContent({ workEditContent: item, index });
                        }}
                      >
                        编辑
                      </span>
                      <span
                        className={classes.fontDelete}
                        onClick={() => {
                          setIsDialogOpen(true);
                          setDeleteItem('work');
                          setWorkEditContent({ workEditContent: item, index });
                        }}
                      >
                        删除
                      </span>
                      公司：{item.companyName} <span className={classes.spanSpace}></span> 职位：
                      {item.jobTitle}
                      <br />
                      工作时间：{dateFormatYearMonthDay(item.atWorkDateRangeStart)}
                      <span className={classes.spanSpace}></span>
                      {dateFormatYearMonthDay(item.atWorkDateRangeEnd)}
                      <br />
                      工作内容：{item.jobDescription}
                      <br />
                      专业：
                      {item.professionalClass &&
                        resultProfessional(item.professionalClass).map(i => {
                          return (
                            <span key={i} style={{ margin: '0 5px' }}>
                              {i}
                            </span>
                          );
                        })}
                      <br />
                      行业：
                      {item.industryClass &&
                        resultIndustryView(item.industryClass).map(i => {
                          return (
                            <span style={{ margin: '0 5px' }} key={i}>
                              {i}
                            </span>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes.textContent} id="project-experience">
            <div className={classes.titleGrid}>
              <h4 className={classes.titleSize}>项目经历</h4>
              <span
                className={classes.fontAddContent}
                onClick={() => {
                  setResumeProject(false);
                  setTimeout(() => {
                    setResumeProject(true);
                  }, 500);
                  setProjectEditStatus('add');
                }}
              >
                <AddBoxIcon fontSize="small" /> 添加
              </span>
            </div>
            <div className={classes.currentText}>
              {resumeProject && (
                <ProjectExperiencesView
                  editStatus={projectEditStatus}
                  editContent={projectEditContent}
                  onClose={() => {
                    setResumeProject(false);
                  }}
                />
              )}
              {consultant.verification?.projectInfoList?.map((projectItem, index) => {
                return (
                  <div key={index} className={classes.childrenItem}>
                    <span
                      className={classes.fontEdit}
                      onClick={() => {
                        setResumeProject(false);
                        setTimeout(() => {
                          setResumeProject(true);
                        }, 500);
                        setProjectEditStatus('edit');
                        setProjectEditContent({ projectEditContent: projectItem, index });
                      }}
                    >
                      编辑
                    </span>
                    <span
                      className={classes.fontDelete}
                      onClick={() => {
                        setIsDialogOpen(true);
                        setDeleteItem('project');
                        setProjectEditContent({ projectEditContent: projectItem, index });
                      }}
                    >
                      删除
                    </span>
                    公司名：{projectItem.projectCompanyName}
                    <span className={classes.spanSpace}></span>
                    项目名：{projectItem.projectName}
                    <br />
                    项目详情:{projectItem.projectDescription}
                    <br />
                    项目时间：{dateFormatYearMonthDay(projectItem.projectDateStart)}
                    <span className={classes.spanSpace}></span>
                    {dateFormatYearMonthDay(projectItem.projectDateEnd)}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={classes.textContent} id="educational-experience">
            <div className={classes.titleGrid}>
              <h4 className={classes.titleSize}>教育经历</h4>
              <span
                className={classes.fontAddContent}
                onClick={() => {
                  setResumeEducation(false);
                  setTimeout(() => {
                    setResumeEducation(true);
                  }, 500);
                  setEducationEditStatus('add');
                }}
              >
                <AddBoxIcon fontSize="small" /> 添加
              </span>
            </div>
            <div className={classes.currentText}>
              {resumeEducation && (
                <EducationView
                  editContent={educationEditContent}
                  editStatus={educationEditStatus}
                  onClose={() => {
                    setResumeEducation(false);
                  }}
                />
              )}
              {consultant.verification?.educationInfoList?.map((educationItem, index) => {
                return (
                  <div key={index} className={classes.childrenItem}>
                    <span
                      className={classes.fontEdit}
                      onClick={() => {
                        setResumeEducation(false);
                        setTimeout(() => {
                          setResumeEducation(true);
                        }, 500);
                        setEducationEditStatus('edit');
                        setEducationEditContent({ educationEditContent: educationItem, index });
                      }}
                    >
                      编辑
                    </span>
                    <span
                      className={classes.fontDelete}
                      onClick={() => {
                        setIsDialogOpen(true);
                        setDeleteItem('education');
                        setEducationEditContent({ educationEditContent: educationItem, index });
                      }}
                    >
                      删除
                    </span>
                    学校：{educationItem.schoolName} <span className={classes.spanSpace}></span>
                    学历：
                    {
                      educationBackground.find(item => item.value === educationItem.education)
                        ?.label
                    }
                    <br />
                    专业：{specialized.find(c => c.value === educationItem.majorType)?.label}
                    专业名称：{educationItem.academicDirections}
                    <br />
                    教育时间：{dateFormatYearMonthDay(educationItem.atSchoolDateRangeStart)}
                    <span className={classes.spanSpace}></span>
                    {dateFormatYearMonthDay(educationItem.atSchoolDateRangeEnd)}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={classes.textContent} id="language">
            <div className={classes.titleGrid}>
              <h4 className={classes.titleSize}>语言</h4>
              <span
                className={classes.fontAddContent}
                onClick={() => {
                  setResumeLanguage(false);
                  setTimeout(() => {
                    setResumeLanguage(true);
                  }, 500);
                  setLanguageEditStatus('add');
                }}
              >
                <AddBoxIcon fontSize="small" /> 添加
              </span>
            </div>
            <div className={classes.currentText}>
              {resumeLanguage && (
                <LanguageView
                  editContent={languageEditContent}
                  editStatus={languageEditStatus}
                  onClose={() => {
                    setResumeLanguage(false);
                  }}
                />
              )}
              {consultant.verification?.languageInfoList?.map((languageItem, index) => {
                return (
                  <div
                    key={index}
                    className={classes.childrenItem}
                    style={{ padding: '1rem 0 1rem 5px' }}
                  >
                    <span
                      className={classes.fontEdit}
                      onClick={() => {
                        setResumeLanguage(false);
                        setTimeout(() => {
                          setResumeLanguage(true);
                        }, 500);
                        setLanguageEditStatus('edit');
                        setLanguageEditContent({ languageEditContent: languageItem, index });
                      }}
                    >
                      编辑
                    </span>
                    <span
                      className={classes.fontDelete}
                      onClick={() => {
                        setIsDialogOpen(true);
                        setDeleteItem('language');
                        setLanguageEditContent({ languageEditContent: languageItem, index });
                      }}
                    >
                      删除
                    </span>
                    语言： {LANGUAGE_WORLD.find(e => e.value === languageItem.languageName)?.label}
                    <span className={classes.spanSpace}></span> 熟练：
                    {Proficiency_level[languageItem.languageLevel]}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={classes.textContent} id="personal-honor">
            <div className={classes.titleGrid}>
              <h4 className={classes.titleSize}>个人荣誉</h4>
              <span
                className={classes.fontAddContent}
                onClick={() => {
                  setResumeHonor(false);
                  setTimeout(() => {
                    setResumeHonor(true);
                  }, 500);
                  setHonorEditStatus('add');
                }}
              >
                <AddBoxIcon fontSize="small" /> 添加
              </span>
            </div>
            <div className={classes.currentText}>
              {resumeHonor && (
                <HonorView
                  onClose={() => setResumeHonor(false)}
                  editStatus={honorEditStatus}
                  editContent={honorEditContent}
                />
              )}
              {consultant.verification?.honorInfoList?.map((honorItem, index) => {
                return (
                  <div key={index} className={classes.childrenItem}>
                    <span
                      className={classes.fontEdit}
                      onClick={() => {
                        setResumeHonor(false);
                        setTimeout(() => {
                          setResumeHonor(true);
                        }, 500);
                        setHonorEditStatus('edit');
                        setHonorEditContent({ honorEditContent: honorItem, index });
                      }}
                    >
                      编辑
                    </span>
                    <span
                      className={classes.fontDelete}
                      onClick={() => {
                        setIsDialogOpen(true);
                        setDeleteItem('honor');
                        setHonorEditContent({ honorEditContent: honorItem, index });
                      }}
                    >
                      删除
                    </span>
                    荣誉：{honorItem.honorTitle} <br /> {honorItem.honorContent}
                    <br /> 时间：
                    {dateFormatYearMonthDay(honorItem.honorDateRangeStart)}
                    <span className={classes.spanSpace}></span>
                    {dateFormatYearMonthDay(honorItem.honorDateRangeEnd)}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={classes.textContent} id="my-certificate">
            <div className={classes.titleGrid}>
              <h4 className={classes.titleSize}>我的证书</h4>
              <span
                className={classes.fontAddContent}
                onClick={() => {
                  setResumeCertificate(false);
                  setTimeout(() => {
                    setResumeCertificate(true);
                  }, 500);
                  setCertificateEditStatus('add');
                }}
              >
                <AddBoxIcon fontSize="small" /> 添加
              </span>
            </div>
            <div className={classes.currentText}>
              {resumeCertificate && (
                <CertificateView
                  editContent={certificateEditContent}
                  editStatus={certificateEditStatus}
                  onClose={() => {
                    setResumeCertificate(false);
                  }}
                />
              )}
              {consultant.verification?.certificateInfoList?.map((certificateItem, index) => {
                return (
                  <div key={index} className={classes.childrenItem}>
                    <span
                      className={classes.fontEdit}
                      onClick={() => {
                        setResumeCertificate(false);
                        setTimeout(() => {
                          setResumeCertificate(true);
                        }, 500);
                        setCertificateEditStatus('edit');
                        setCertificateEditContent({
                          certificateEditContent: certificateItem,
                          index,
                        });
                      }}
                    >
                      编辑
                    </span>
                    <span
                      className={classes.fontDelete}
                      onClick={() => {
                        setIsDialogOpen(true);
                        setDeleteItem('certificate');
                        setCertificateEditContent({
                          certificateEditContent: certificateItem,
                          index,
                        });
                      }}
                    >
                      删除
                    </span>
                    {certificateItem.certificateName} <br /> 类型：
                    {
                      CertificateTypeArray.find(
                        c => c.value == certificateItem.certificateDescription
                      )?.label
                    }
                  </div>
                );
              })}
            </div>
          </div>

          <div className={classes.textContent} id="other-info">
            <div className={classes.titleGrid}>
              <h4 className={classes.titleSize}>其他</h4>
            </div>
            <div className={classes.currentText}>
              <span
                className={classes.fontEdit}
                onClick={() => {
                  setResumeOtherSetting(!resumeOtherSetting);
                }}
              >
                编辑
              </span>
              {resumeOtherSetting && (
                <OtherSettingView
                  onClose={() => {
                    setResumeOtherSetting(false);
                  }}
                />
              )}
              {!resumeOtherSetting && (
                <div>
                  {consultant.verification?.settings?.findingJob == 'FindingJob'
                    ? '正在寻找项目'
                    : '暂时不考虑寻找项目'}
                  <br />
                  工作场景：{workScenario?.remote && '远程'}
                  <span className={classes.spanSpace}></span>
                  {workScenario?.onsite && '现场'} <span className={classes.spanSpace}></span>
                  工作方式：
                  {consultant.verification?.settings?.workType == 'FullTimeJob'
                    ? '全职'
                    : '自由职业'}
                  <br />
                  {/* 是否公开档案: */}
                  {/* {consultant.verification?.settings?.workDossier === '1' ? '是' : '否'} */}
                  顾问费率/小时: {consultant.verification?.settings?.expectedFeePerHour}
                  <span className={classes.spanSpace}></span>
                  顾问费率/月：{consultant.verification?.settings?.expectedFeePerMonth}
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
      <div className={classes.attachment}>
        <Card variant="outlined" className={classes.attachmentCard}>
          <div className={classes.contentBetween}>
            <span className={classes.textAttachment}>导出简历</span>
            <span
              className={classes.textAttachment}
              onClick={() => {
                copyToClipboard();
              }}
            >
              分享
            </span>
          </div>
        </Card>
        {/* <Card variant="outlined" className={classes.attachmentCard}>
          <div className={classes.contentBetween}>
            <span className={classes.textAttachment}>附件</span>
            <AddIcon
              onClick={() => {
                setIsUploadDialogOpen(true);
              }}
            />
          </div>
        </Card> */}
        <Card variant="outlined" className={classes.attachmentCard}>
          <div className={classes.contentBetween}>
            <span className={classes.textAttachment}>隐私设置</span>
          </div>
          <div className={classNames(classes.contentBetween, classes.textFont)}>
            简历公开
            <Switch
              size="small"
              //   checked={state.checkedB}
              //   onChange={handleChange}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
        </Card>
      </div>
      <GeneralDialog
        isOpen={isDialogOpen}
        titleText="删除 信息"
        contentText="确认要删除此信息吗？"
        handleEdit={(): void => {
          setIsDialogOpen(false);
          if (deleteItem == 'work') {
            workItemDelete();
          }
          if (deleteItem == 'project') {
            projectItemDelete();
          }
          if (deleteItem == 'education') {
            educationItemDelete();
          }
          if (deleteItem == 'language') {
            languageItemDelete();
          }
          if (deleteItem == 'honor') {
            honorItemDelete();
          }
          if (deleteItem == 'certificate') {
            certificateDelete();
          }
        }}
        handleDialogClose={(): void => {
          setIsDialogOpen(false);
        }}
      />
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(ResumeView);
