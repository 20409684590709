import React, { ReactElement } from 'react';
import {
  Card,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import DraftsIcon from '@material-ui/icons/Drafts';
import InboxIcon from '@material-ui/icons/Inbox';
import { navigate } from 'gatsby';

const styles = () =>
  createStyles({
    root: {
      position: 'relative',
    },
  });

const PersonalAccountNavigation = ({ classes }: Props): ReactElement => {
  return (
    <div className={classes.root}>
      <Card variant="outlined">
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="首页" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate('/consultant/accountManagement');
            }}
          >
            <ListItemIcon>
              <DraftsIcon />
            </ListItemIcon>
            <ListItemText primary="账号管理" />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="权限管理" />
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="个人信息管理" />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="身份验证" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate('/consultant/resume');
            }}
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="简历" />
          </ListItem>
        </List>
      </Card>
      {/* <Divider /> */}
      {/* <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button>
          <ListItemText primary="Trash" />
        </ListItem>
      </List> */}
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}
export default withStyles(styles)(PersonalAccountNavigation);
