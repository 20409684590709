import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  StyleRules,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18n from 'i18next';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';

import { saveConsultantVerification } from '../../actions/consultant/consultantVerificationActions';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import { CompressFile } from '../../utils/util';
import { createConsultantProjectInfo } from '../../views/consultant/consultant.model';
import { projectEditContentType } from '../../views/consultant/ResumeView';
import { GeneralDialog } from '../Dialog';

const styles = (): StyleRules =>
  createStyles({
    borders: {
      position: 'relative',
      verticalAlign: 'middle',
      border: '1px solid #a2a294',
    },
    helperText: {
      color: 'red',
    },
    submit: {
      textAlign: 'center',
    },
    AvatarBorder: {
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    buttonHeight: {
      margin: 'auto 0',
      height: '36.5px',
    },
    avatarColor: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      margin: '20px 0',
    },
    contentTop: {
      paddingBottom: '20px',
    },
    cardWidth: {
      width: '100%',
      height: '40px',
      paddingTop: '8px',
    },
  });
const ProjectExperiencesView = ({
  classes,
  onClose,
  editStatus,
  editContent,
}: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const verificationId = consultant?.verification?.id;
  const { t } = useTranslation();
  i18n.loadNamespaces('ProjectExperienceInputView');

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);

  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      projectInfoList: createConsultantProjectInfo(),
    },
    mode: 'onChange',
  });
  const { fields: projectImageFileList, append, remove } = useFieldArray({
    control,
    name: `projectInfoList.projectImageFileList`,
  });
  const { isDirty, errors, isValid } = useFormState({ control });

  const handleOnSave = async (fileObjects: FileObject[]): Promise<void> => {
    setIsUploadDialogOpen(false);
    for (let index = 0; index < fileObjects.length; index++) {
      const element = fileObjects[index].file;
      const fileToUpload = await CompressFile(element);
      append({ file: fileToUpload });
    }
  };

  const savaInfo = async () => {
    if (isValid) {
      console.log(getValues('projectInfoList'));

      if (editStatus == 'add') {
        const projectInfoListData = [
          ...JSON.parse(JSON.stringify(consultant.verification?.projectInfoList)),
          getValues('projectInfoList'),
        ];
        for (let i = 0; i < projectInfoListData.length; i++) {
          if (
            projectInfoListData[i].projectDateStart &&
            typeof projectInfoListData[i].projectDateStart == 'string'
          ) {
            projectInfoListData[i].projectDateStart = new Date(
              projectInfoListData[i].projectDateStart
            );
          }
          if (
            projectInfoListData[i].projectDateEnd &&
            typeof projectInfoListData[i].projectDateEnd == 'string'
          ) {
            projectInfoListData[i].projectDateEnd = new Date(projectInfoListData[i].projectDateEnd);
          }
        }

        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            projectInfoList: projectInfoListData,
            status: 'LANGUAGE_REGISTERED',
          })
        );
      }
      if (editStatus == 'edit') {
        const saveEditData = JSON.stringify(consultant.verification?.projectInfoList);
        const projectInfoListData = JSON.parse(saveEditData);

        if (editContent?.index != undefined && editContent?.index != null) {
          projectInfoListData[editContent.index] = getValues('projectInfoList');
          for (let i = 0; i < projectInfoListData.length; i++) {
            if (
              projectInfoListData[i].projectDateStart &&
              typeof projectInfoListData[i].projectDateStart == 'string'
            ) {
              projectInfoListData[i].projectDateStart = new Date(
                projectInfoListData[i].projectDateStart
              );
            }
            if (
              projectInfoListData[i].projectDateEnd &&
              typeof projectInfoListData[i].projectDateEnd == 'string'
            ) {
              projectInfoListData[i].projectDateEnd = new Date(
                projectInfoListData[i].projectDateEnd
              );
            }
          }

          dispatch(
            saveConsultantVerification({
              id: verificationId,
              consultantType: 'PERSONAL',
              projectInfoList: projectInfoListData,
              status: 'LANGUAGE_REGISTERED',
            })
          );
        }
      }

      setIsDialogOpen(false);
      onClose && onClose();
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('数据格式不正确'),
        })
      );
    }
  };

  useEffect(() => {
    if (editContent && editStatus == 'edit') {
      setValue('projectInfoList', editContent.projectEditContent);
      if (
        editContent.projectEditContent.projectImageFileList &&
        editContent.projectEditContent.projectImageFileList?.length > 0
      ) {
        setValue(
          'projectInfoList.projectImageFileList',
          editContent.projectEditContent.projectImageFileList
        );
      }
    }
  }, [editContent]);

  return (
    <>
      <Grid item container xs={12} className={classes.contentTop}></Grid>{' '}
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <TextField
                variant="outlined"
                required
                size="small"
                fullWidth
                autoFocus={false}
                label={t('project_experience_input_view:project_name')}
                error={!!errors?.projectInfoList?.projectName}
                helperText={
                  errors?.projectInfoList?.projectName &&
                  t('project_experience_input_view:project_name_not_empty')
                }
                {...field}
              />
            )}
            name={`projectInfoList.projectName`}
            rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <TextField
                variant="outlined"
                required
                fullWidth
                size="small"
                autoFocus={false}
                label={t('project_experience_input_view:affiliated_company')}
                error={!!errors?.projectInfoList?.projectCompanyName}
                helperText={
                  errors?.projectInfoList?.projectCompanyName &&
                  t('project_experience_input_view:affiliated_company_not_empty')
                }
                {...field}
              />
            )}
            name={`projectInfoList.projectCompanyName`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between" style={{ marginTop: '1rem' }}>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <FormControl
                  variant="outlined"
                  error={!!errors?.projectInfoList?.projectDateStart}
                  fullWidth
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      size="small"
                      fullWidth
                      variant="inline"
                      format="yyyy-MM"
                      views={['year', 'month']}
                      id="date-picker-start"
                      label={t('project_experience_input_view:start_time')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      {...field}
                      value={field.value ?? null}
                    />
                  </MuiPickersUtilsProvider>
                  {errors?.projectInfoList?.projectDateStart && (
                    <FormHelperText>
                      {t('project_experience_input_view:start_time_not_empty')}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
            name={`projectInfoList.projectDateStart`}
            rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    size="small"
                    format="yyyy-MM"
                    views={['year', 'month']}
                    id="date-picker-end"
                    label={t('project_experience_input_view:end_time')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    {...field}
                    value={field.value ?? null}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            )}
            name={`projectInfoList.projectDateEnd`}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      <Controller
        render={({ field }) => (
          <TextField
            variant="outlined"
            required
            fullWidth
            style={{ margin: '1rem 0' }}
            autoFocus={false}
            multiline
            size="small"
            minRows={4}
            label={t('project_experience_input_view:project_description')}
            error={!!errors?.projectInfoList?.projectDescription}
            helperText={
              errors?.projectInfoList?.projectDescription &&
              t('project_experience_input_view:project_description_not_empty')
            }
            {...field}
          />
        )}
        name={`projectInfoList.projectDescription`}
        control={control}
        rules={{ required: true }}
      ></Controller>
      <Grid item container xs={12} className={classes.submit}>
        <Grid item xs={7}>
          <Typography variant="body2" align="left" color="secondary" className={classes.attachment}>
            {t('project_experience_input_view:upload_file_description')}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              setIsUploadDialogOpen(true);
            }}
          >
            {t('project_experience_input_view:upload_documents')}
          </Button>
        </Grid>
        <DropzoneDialogBase
          fileObjects={[]}
          open={isUploadDialogOpen}
          onAdd={(fileObjects): void => {
            handleOnSave(fileObjects);
          }}
          showPreviews={true}
          maxFileSize={50000000}
          dropzoneText={t('project_experience_input_view:drag_files')}
          dialogTitle={t('project_experience_input_view:uploading')}
          submitButtonText={t('project_experience_input_view:uploading')}
          cancelButtonText={t('project_experience_input_view:cancellation')}
          onClose={(): void => {
            setIsUploadDialogOpen(false);
          }}
        />
      </Grid>
      {projectImageFileList.map((file, indexImg) => {
        return (
          <Grid key={file.id} item xs={12}>
            <Controller
              render={({ field: { value } }) => (
                <>
                  <img src={value.file} width={300} />

                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      remove(indexImg);
                    }}
                  >
                    {t('project_experience_input_view:delete_picture')}
                  </Button>
                </>
              )}
              name={`projectInfoList.projectImageFileList.${indexImg}`}
              control={control}
            ></Controller>
          </Grid>
        );
      })}
      <div className={classes.buttonContent}>
        <Button
          color="primary"
          onClick={() => {
            onClose && onClose();
          }}
        >
          取消
        </Button>
        {editStatus === 'edit' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('projectInfoList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认修改
          </Button>
        )}
        {editStatus === 'add' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('projectInfoList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认添加
          </Button>
        )}
      </div>
      <GeneralDialog
        isOpen={isDialogOpen}
        titleText="修改信息"
        contentText="确认要修改这些信息吗？"
        handleEdit={(): void => {
          savaInfo();
        }}
        handleDialogClose={(): void => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  editStatus?: string;
  editContent?: projectEditContentType;
}

export default withStyles(styles)(ProjectExperiencesView);
