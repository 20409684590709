import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import i18n from 'i18next';

import ConsultantRegistrationNotice from '../ConsultantRegistrationNotice';
export const BasicDialog = ({
  isOpen = false,
  onClose,
  title = 'DialogTemplate',
  children,
}: Props): React.ReactElement => {
  const handleClose = (event: React.ChangeEvent): void => {
    onClose?.(event);
  };

  return (
    <Dialog
      disableEnforceFocus
      disablePortal
      disableAutoFocus
      onClose={handleClose}
      open={isOpen}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{title}</DialogTitle>
      {children}
    </Dialog>
  );
};

export const DeleteConfirmDialog = ({
  isOpen,
  handleDelete,
  handleDialogClose,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('Dialog');
  return (
    <Dialog open={isOpen} maxWidth="md">
      <DialogTitle>{t('dialog:prompt')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dialog:delete_str')}</DialogContentText>
      </DialogContent>

      <DialogActions style={{ marginTop: '3em' }}>
        <Button variant="contained" color="primary" onClick={handleDelete}>
          {t('dialog:delete')}
        </Button>
        <Button onClick={handleDialogClose}>{t('dialog:cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditInformationJumpDialog = ({
  isOpen,
  handleEdit,
  handleDialogClose,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('Dialog');
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{t('dialog:prompt')}</DialogTitle>
      <DialogContent>
        <DialogContentText> {t('dialog:save_submission')}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleEdit} variant="contained" color="primary">
          {t('dialog:confirm')}
        </Button>
        <Button onClick={handleDialogClose}>{t('dialog:cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};
export const ApplyConfirmDialog = ({
  isOpen,
  handleConfirm,
  handleDialogClose,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} maxWidth="md">
      <DialogTitle>提示</DialogTitle>
      <DialogContent>
        <DialogContentText>
          申请该岗位将会把您的个人信息提供给对方企业，请确认是否要继续申请此岗位？
        </DialogContentText>
      </DialogContent>

      <DialogActions style={{ marginTop: '3em' }}>
        <Button onClick={handleConfirm}>确认</Button>
        <Button onClick={handleDialogClose}>取消</Button>
      </DialogActions>
    </Dialog>
  );
};
export const ConfirmDialog = ({
  isOpen,
  handleConfirm,
  handleDialogClose,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} maxWidth="md">
      <DialogTitle>提示</DialogTitle>
      <DialogContent>
        <DialogContentText>确定要发布吗？</DialogContentText>
      </DialogContent>

      <DialogActions style={{ marginTop: '3em' }}>
        <Button onClick={handleConfirm}>发布</Button>
        <Button onClick={handleDialogClose}>取消</Button>
      </DialogActions>
    </Dialog>
  );
};
export const DeleteComment = ({
  isOpen,
  handleDelete,
  handleDialogClose,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} maxWidth="md">
      <DialogTitle>提示</DialogTitle>
      <DialogContent>
        <DialogContentText>确定要删除此评论吗？</DialogContentText>
      </DialogContent>

      <DialogActions style={{ marginTop: '3em' }}>
        <Button onClick={handleDelete} variant="contained">
          删除
        </Button>
        <Button onClick={handleDialogClose}>取消</Button>
      </DialogActions>
    </Dialog>
  );
};
export const RegistrationPolicyDialog = ({
  isOpen,
  handleDialogClose,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('RegistrationPolicyDialog');
  const [checked, setChecked] = React.useState(false);
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>{t('registration_policy_dialog:prompt')}</DialogTitle>
      <DialogContent>
        <ConsultantRegistrationNotice></ConsultantRegistrationNotice>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>
          <Checkbox
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
            color="primary"
          />
          不再提醒我
        </span>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleDialogClose && handleDialogClose();
            checked && localStorage.setItem('verificationConsultantPolicyPrompt', 'true');
          }}
        >
          {t('registration_policy_dialog:confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ConsultantConfirmsCompletionOfRegistrationDialog = ({
  isOpen,
  handleDialogClose,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('RegistrationPolicyDialog');
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>{t('registration_policy_dialog:prompt')}</DialogTitle>
      <DialogContent>请最后预览并在下方点击提交认证 成为Fintegrity傅通顾问!</DialogContent>
      <DialogActions>
        {/* <Button onClick={handleDelete}>确认</Button> */}
        <Button variant="contained" color="primary" onClick={handleDialogClose}>
          了解
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ConsultantBasicInformationEditingDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
  children,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} maxWidth="lg">
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleEdit} variant="contained" color="primary">
          <SpellcheckIcon fontSize="small" /> 申请修改
        </Button>
        <Button onClick={handleDialogClose} variant="contained" color="primary">
          <NotInterestedIcon fontSize="small" /> 关闭
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ReadingAgreementDialog = ({
  isOpen,
  handleDialogClose,
  children,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('Dialog');
  const [count, setCount] = useState<number>(15);
  const [btnShow, setBtnShow] = useState<Boolean>(true);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        setBtnShow(false);
        clearInterval(timer);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [count]);

  return (
    <Dialog open={isOpen} maxWidth="lg">
      {children}
      <div style={{ textAlign: 'center' }}>
        <Button
          disabled={btnShow ? true : false}
          onClick={handleDialogClose}
          variant="contained"
          color="primary"
        >
          {t('dialog:confirm_read')}
          {count > 0 ? count : ''}
        </Button>
      </div>
    </Dialog>
  );
};

export const CompanyPublishProjectDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
  children,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose} disableEscapeKeyDown={true}>
      <DialogTitle>确认发布项目</DialogTitle>
      <DialogContent>
        {children}
        *项目发布后，如申请成功是否关闭项目需求？关闭项目项目需求不再对外显示次项目信息。
      </DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button onClick={handleEdit} variant="contained" color="primary">
          是
        </Button>
        <Button onClick={handleDialogClose} variant="contained">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CancelCompanyPublishProjectDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>取消发布项目</DialogTitle>
      <DialogContent>确认要取消该项目么？</DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button onClick={handleEdit} variant="contained" color="primary">
          是
        </Button>
        <Button onClick={handleDialogClose} variant="contained">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditCompanyPublishProjectDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>修改项目</DialogTitle>
      <DialogContent>确认要修改该项目信息么？</DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button onClick={handleEdit} variant="contained" color="primary">
          是
        </Button>
        <Button onClick={handleDialogClose} variant="contained">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const PassCompanyBiddingProjectDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>通过初次筛选</DialogTitle>
      <DialogContent>确认该申请这通过初筛吗？</DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button onClick={handleEdit} variant="contained" color="primary">
          是
        </Button>
        <Button onClick={handleDialogClose} variant="contained">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const GeneralDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
  titleText,
  contentText,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>{titleText}</DialogTitle>
      <DialogContent>{contentText}</DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button onClick={handleEdit} variant="contained" color="primary">
          是
        </Button>
        <Button onClick={handleDialogClose} variant="contained">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const RefuseCompanyBiddingProjectDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>此项申请不合适</DialogTitle>
      <DialogContent>确认拒绝此项申请信息么？</DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button onClick={handleEdit} variant="contained" color="primary">
          是
        </Button>
        <Button onClick={handleDialogClose} variant="contained">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AdminCloseProjectDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>关闭项目</DialogTitle>
      <DialogContent>确认要关闭该项目么？</DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button onClick={handleEdit} variant="contained" color="primary">
          是
        </Button>
        <Button onClick={handleDialogClose} variant="contained">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ConsultantApplyingBiddingProjectDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
  isEdit,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>{isEdit ? '修改申请' : '确认申请'}</DialogTitle>
      <DialogContent>
        {isEdit ? '确认要修改该项目的申请信息吗？' : '确认要申请该项目吗？'}
      </DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button onClick={handleEdit} variant="contained" color="primary">
          是
        </Button>
        <Button onClick={handleDialogClose} variant="contained">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export const ConsultantRefuseProjectDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>拒绝邀请</DialogTitle>
      <DialogContent>确认拒绝项目邀请吗？</DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button onClick={handleEdit} variant="contained" color="primary">
          是
        </Button>
        <Button onClick={handleDialogClose} variant="contained">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ConsultantBreakProjectDialog = ({
  isOpen,
  handleDialogClose,
  handleEdit,
}: Props): React.ReactElement => {
  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleDialogClose}>
      <DialogTitle>申请项目终止</DialogTitle>
      <DialogContent>确认要终止当前进行中的项目吗？项目申请终止后24内处理。</DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button onClick={handleEdit} variant="contained" color="primary">
          是
        </Button>
        <Button onClick={handleDialogClose} variant="contained">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export interface Props {
  isOpen: boolean;
  onClose?: (event: React.ChangeEvent) => void;
  handleDelete?: () => void;
  handleConfirm?: () => void;
  handleEdit?: () => void;
  handleDialogClose?: () => void;
  title?: string;
  children?: ReactElement;
  isEdit?: boolean;
  titleText?: string;
  contentText?: string;
}
