import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StyleRules,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Cascader } from 'antd';
import i18n from 'i18next';

import { saveConsultantVerification } from '../../actions/consultant/consultantVerificationActions';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import dataIndustry from '../../utils/industry';
import specialData from '../../utils/special';
import { createConsultantWorkInfo } from '../../views/consultant/consultant.model';
import { workEditContentType } from '../../views/consultant/ResumeView';
import { GeneralDialog } from '../Dialog';

const styles = (): StyleRules =>
  createStyles({
    borders: {
      position: 'relative',
      verticalAlign: 'middle',
    },
    helperText: {
      color: 'red',
    },
    submit: {
      textAlign: 'center',
    },
    AvatarBorder: {
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    buttonHeight: {
      margin: 'auto 0',
      height: '36.5px',
    },
    avatarColor: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      marginBottom: '20px',
    },
    contentTop: {
      paddingBottom: '20px',
    },
    cardWidth: {
      width: '100%',
      height: '40px',
      paddingTop: '8px',
    },
  });
const WorkInfoView = ({ classes, onClose, editStatus, workEditContent }: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const verificationId = consultant?.verification?.id;
  const { SHOW_CHILD } = Cascader;
  const { t } = useTranslation();
  i18n.loadNamespaces('WorkInputView');

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      workInfoList: createConsultantWorkInfo(),
    },
    mode: 'onChange',
  });

  const { isDirty, errors, isValid } = useFormState({ control });

  const savaInfo = async () => {
    if (isValid) {
      if (editStatus == 'add') {
        const data = { ...getValues('workInfoList') };
        data.industryClass = JSON.stringify(getValues('workInfoList.industryClass'));
        data.professionalClass = JSON.stringify(getValues('workInfoList.professionalClass'));
        // if (consultant.verification?.workInfoList) {
        const workInfoListData = [
          ...JSON.parse(JSON.stringify(consultant.verification?.workInfoList)),
          data,
        ];
        for (let i = 0; i < workInfoListData.length; i++) {
          if (
            workInfoListData[i].atWorkDateRangeEnd &&
            typeof workInfoListData[i].atWorkDateRangeEnd == 'string'
          ) {
            workInfoListData[i].atWorkDateRangeEnd = new Date(
              workInfoListData[i].atWorkDateRangeEnd
            );
          }
          if (
            workInfoListData[i].atWorkDateRangeStart &&
            typeof workInfoListData[i].atWorkDateRangeStart == 'string'
          ) {
            workInfoListData[i].atWorkDateRangeStart = new Date(
              workInfoListData[i].atWorkDateRangeStart
            );
          }
        }

        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            workInfoList: workInfoListData,
            status: 'LANGUAGE_REGISTERED',
          })
        );
        // }
      }
      if (editStatus == 'edit') {
        const saveEditData = JSON.stringify(consultant.verification?.workInfoList);
        const data = JSON.parse(saveEditData);
        if (workEditContent?.index !== undefined && workEditContent.index !== null) {
          data[workEditContent.index] = getValues('workInfoList');
          for (let i = 0; i < data.length; i++) {
            if (data[i].atWorkDateRangeEnd && typeof data[i].atWorkDateRangeEnd == 'string') {
              data[i].atWorkDateRangeEnd = new Date(data[i].atWorkDateRangeEnd);
            }
            if (data[i].atWorkDateRangeStart && typeof data[i].atWorkDateRangeStart == 'string') {
              data[i].atWorkDateRangeStart = new Date(data[i].atWorkDateRangeStart);
            }

            if (data[i].industryClass && typeof data[i].industryClass != 'string') {
              data[i].industryClass = JSON.stringify(data[i].industryClass);
            }
            if (data[i].professionalClass && typeof data[i].professionalClass != 'string') {
              data[i].professionalClass = JSON.stringify(data[i].professionalClass);
            }
          }
          dispatch(
            saveConsultantVerification({
              id: verificationId,
              consultantType: 'PERSONAL',
              workInfoList: data,
              status: 'LANGUAGE_REGISTERED',
            })
          );
          // console.log(
          //   'workEditContent',
          //   consultant.verification?.workInfoList,
          //   getValues('workInfoList'),
          //   data
          // );
        }
      }

      setIsDialogOpen(false);
      onClose && onClose();
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('数据格式不正确'),
        })
      );
    }
  };

  useEffect(() => {
    if (workEditContent && editStatus == 'edit') {
      const workInfoData = JSON.stringify(workEditContent.workEditContent);
      const data = JSON.parse(workInfoData);

      setValue('workInfoList', data);
      if (typeof data.professionalClass == 'string') {
        setValue('workInfoList.professionalClass', JSON.parse(data.professionalClass));
      }
      if (typeof data.industryClass == 'string') {
        setValue('workInfoList.industryClass', JSON.parse(data.industryClass));
      }
    }
  }, [workEditContent]);

  return (
    <>
      <Grid item container xs={12} className={classes.contentTop}>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <TextField
                variant="outlined"
                required
                size="small"
                fullWidth
                autoFocus={false}
                placeholder="公司名称"
                error={!!errors?.workInfoList?.companyName}
                helperText={
                  errors?.workInfoList?.companyName && t('work_input_view:company_not_empty')
                }
                {...field}
              />
            )}
            name={`workInfoList.companyName`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <TextField
                variant="outlined"
                required
                fullWidth
                size="small"
                autoFocus={false}
                placeholder="岗位名称"
                error={!!errors?.workInfoList?.jobTitle}
                helperText={
                  errors?.workInfoList?.jobTitle && t('work_input_view:position_not_empty')
                }
                {...field}
              />
            )}
            name={`workInfoList.jobTitle`}
            control={control}
            rules={{
              required: true,
            }}
          ></Controller>
        </Grid>
        <Grid container item xs={12} justifyContent="space-between" style={{ marginTop: '5px' }}>
          <Grid item xs={5}>
            <Controller
              render={({ field }) => {
                return (
                  <FormControl
                    variant="outlined"
                    error={!!errors?.workInfoList?.atWorkDateRangeStart}
                    fullWidth
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        size="small"
                        variant="inline"
                        views={['year', 'month']}
                        format="yyyy-MM"
                        id="date-picker-start"
                        label={t('work_input_view:entry_time')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        {...field}
                        value={field.value ?? null}
                      />
                    </MuiPickersUtilsProvider>
                    {errors?.workInfoList?.atWorkDateRangeStart && (
                      <FormHelperText>{t('work_input_view:onboarding_not_empty')}</FormHelperText>
                    )}
                  </FormControl>
                );
              }}
              name={`workInfoList.atWorkDateRangeStart`}
              control={control}
              rules={{ required: true }}
            ></Controller>
          </Grid>
          <Grid item xs={5}>
            <Controller
              render={({ field }) => (
                <FormControl variant="outlined" fullWidth>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      size="small"
                      fullWidth
                      variant="inline"
                      format="yyyy-MM"
                      views={['year', 'month']}
                      id="date-picker-end"
                      label={t('work_input_view:time_of_resignation')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      {...field}
                      value={field.value ?? null}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              )}
              name={`workInfoList.atWorkDateRangeEnd`}
              control={control}
            ></Controller>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="space-between" style={{ marginTop: '5px' }}>
          <Grid item xs={5}>
            <Grid item xs={12} className={classes.borders}>
              <Controller
                render={({ field }) => (
                  <Cascader
                    bordered={false}
                    placeholder="选择专业"
                    className={classes.cardWidth}
                    options={specialData}
                    size="small"
                    multiple
                    maxTagCount="responsive"
                    showCheckedStrategy={SHOW_CHILD}
                    {...field}
                  />
                )}
                name={`workInfoList.professionalClass`}
                control={control}
              ></Controller>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item xs={12} className={classes.borders}>
              <Controller
                render={({ field }) => (
                  <Cascader
                    bordered={false}
                    placeholder="选择行业"
                    className={classes.cardWidth}
                    options={dataIndustry}
                    size="small"
                    multiple
                    maxTagCount="responsive"
                    showCheckedStrategy={SHOW_CHILD}
                    {...field}
                  />
                )}
                name={`workInfoList.industryClass`}
                control={control}
              ></Controller>
            </Grid>
          </Grid>
        </Grid>
        <Controller
          render={({ field }) => (
            <TextField
              variant="outlined"
              required
              style={{ marginTop: '5px' }}
              fullWidth
              autoFocus={false}
              size="small"
              multiline
              minRows={4}
              id="workContent"
              placeholder="工作内容"
              {...field}
            />
          )}
          name={`workInfoList.jobDescription`}
          control={control}
        ></Controller>
        <Grid container item xs={12} justifyContent="space-between" style={{ marginTop: '5px' }}>
          <Grid item xs={5}>
            <Controller
              render={({ field }) => {
                return (
                  <FormControl error={!!errors?.workInfoList?.wayOfWorking} fullWidth>
                    <InputLabel id="label-work-type-2">
                      {t('work_input_view:working_mode')}
                    </InputLabel>
                    <Select
                      labelId="label-work-type-2"
                      id="wayOfWorking"
                      label={t('work_input_view:Working_mode')}
                      fullWidth
                      {...field}
                    >
                      <MenuItem value={1}>{t('work_input_view:full_time')}</MenuItem>
                      <MenuItem value={2}>{t('work_input_view:part_time_job')}</MenuItem>
                    </Select>
                    {errors?.workInfoList?.wayOfWorking && (
                      <FormHelperText>{t('work_input_view:Working_mode_not_empty')}</FormHelperText>
                    )}
                  </FormControl>
                );
              }}
              name={`workInfoList.wayOfWorking`}
              rules={{
                required: true,
              }}
              control={control}
            ></Controller>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.buttonContent}>
        <Button
          color="primary"
          onClick={() => {
            onClose && onClose();
          }}
        >
          取消
        </Button>
        {editStatus === 'edit' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('workInfoList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认修改
          </Button>
        )}
        {editStatus === 'add' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isDirty) {
                trigger('workInfoList');
                setIsDialogOpen(true);
              }
            }}
          >
            确认添加
          </Button>
        )}
      </div>

      <GeneralDialog
        isOpen={isDialogOpen}
        titleText="修改信息"
        contentText="确认要修改这些信息吗？"
        handleEdit={(): void => {
          savaInfo();
        }}
        handleDialogClose={(): void => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  editStatus?: string;
  workEditContent?: workEditContentType;
}

export default withStyles(styles)(WorkInfoView);
