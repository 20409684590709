import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gql } from '@apollo/client';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core';

import { anonymousGetConsultantById } from '../actions/versionActions';
// import { ClassNameMap } from '@material-ui/styles';
import imgFt from '../images/ft4.jpg';
import CertificateTypeArray from '../utils/CertificateType';
import CITY from '../utils/city';
import cityChild from '../utils/cityChild';
import { dateFormatYearMonthDay } from '../utils/converter';
import educationBackground from '../utils/educationBackground';
import Industry from '../utils/industry';
import { industryFilterHandle } from '../utils/industryFilter';
import LANGUAGE_WORLD from '../utils/languageType';
import { searchLabelsByValue } from '../utils/searchUtils';
import Professional from '../utils/special';
import specialized from '../utils/specialized';
import { Proficiency_level } from '../views/consultant/ResumeView';
const styles = (): StyleRules =>
  createStyles({
    pdfContext: {
      width: '100%',
      minHeight: '100vh',
      position: 'relative',
      padding: '20px',
    },
    pdfWatermark: {
      position: 'relative',
      left: 40,
      margin: '30px',
      transform: 'rotate(345deg)',
      fontSize: '20px',
    },
    pdfBodyMark: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      opacity: '0.1',
      pointerEvents: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      zIndex: 9999,
    },
    spanSpace: {
      marginLeft: '1rem',
      color: '#9b9bbe',
    },
    pdfUl: {
      position: 'relative',
      listStyle: 'none',
      padding: '0',
      margin: '0',
      width: '100%',
    },
  });

export const ANONYMOUS_GET_CONSULTANT = gql`
  query QUERY($consultantId: Long!) {
    anonymousGetConsultantById(consultantId: $consultantId) {
      id
      verification {
        id
        consultantType
        status
        basicInfo {
          id
          avatar
          nationality
          regionProvince
          regionCity
          firstName
          lastName
          phoneNumber
          birthday
          gender
          birthYear
          birthMonth
          passportType
          passportId
          contactEmail
          nowAddressProvince
          nowAddressCity
          nowAddressDetail
          nowAddressDistrict
          personalDescription
        }
        educationInfoList {
          id
          education
          schoolName
          majorType
          academicDirections
          atSchoolDateRangeStart
          atSchoolDateRangeEnd
          educationFileList {
            id
            file
          }
        }
        workInfoList {
          id
          companyName
          wayOfWorking
          jobTitle
          industryClass
          professionalClass
          atWorkDateRangeStart
          atWorkDateRangeEnd
          jobDescription
        }
        projectInfoList {
          id
          projectDateStart
          projectCompanyName
          projectImageFileList {
            id
            file
          }
          projectDateEnd
          projectName
          projectDescription
        }
        languageInfoList {
          id
          languageLevel
          languageName
        }
        honorInfoList {
          id
          honorTitle
          honorDateRangeStart
          honorDateRangeEnd
          honorContent
        }
        certificateInfoList {
          certificateName
          certificateDescription
          certificateFileList {
            id
            file
          }
        }
        otherInfo {
          id
          background
          description
          strength
        }
        settings {
          findingJob
          id
          workDossier
          workType
          workScenario
          expectedFeePerHour
          expectedFeePerMonth
        }
      }
    }
  }
`;
const waterMark = (): ReactElement[] => {
  const element = document.getElementById('pdfContent');
  const height = element?.offsetHeight;
  let count = 20;
  if (height) {
    if (height > 7000) {
      count = 80;
    } else if (height > 6000) {
      count = 70;
    } else if (height > 5000) {
      count = 60;
    } else if (height > 4000) {
      count = 50;
    } else if (height > 3000) {
      count = 40;
    } else if (height > 2000) {
      count = 30;
    }
  }
  return Array.from({ length: count }, (_, i) => (
    <div
      style={{
        position: 'relative',
        left: 40,
        margin: '30px',
        transform: 'rotate(345deg)',
        fontSize: '20px',
      }}
      key={i}
    >
      Fintegrity 傅通咨询
    </div>
  ));
};

const PdfTemplate = ({ classes, consultantId }: Props): ReactElement => {
  const dispatch = useDispatch();
  const [divMark, setDivMark] = useState<ReactElement[]>();
  const [consultant, setConsultant] = useState<ConsultantVerification>();
  const [workScenario, setWorkScenario] = useState<workScenarioSelect>();

  const fetchData = async (id: number) => {
    const data = await dispatch(anonymousGetConsultantById(id));
    setConsultant(data);
    setWorkScenario(JSON.parse(data?.settings?.workScenario));
  };

  const resultProfessional = (professionalList: string): string[] => {
    const data = JSON.parse(professionalList);
    const valuesToSearch = data.map((subArray: []) => subArray[subArray.length - 1]);
    const zhi = searchLabelsByValue(Professional, valuesToSearch);
    return zhi;
  };

  const resultIndustryView = (industry: string | CascadeArrayType[]): [] => {
    const industryObj = industry ? JSON.parse(industry.toString()) : '';
    if (industryObj) {
      const industry = industryFilterHandle(industryObj, Industry);
      return industry as [];
    }
    return [];
  };

  useEffect(() => {
    setDivMark(waterMark());
    if (typeof location !== 'undefined') {
      const id = parseInt(location.hash.replace('#', ''));
      id && fetchData(id);
    }
    if (consultantId) {
      console.log('pdfTemplate', consultantId);
      fetchData(consultantId);
    }
  }, []);

  return (
    <div className={classes.pdfContext} id="pdfContent">
      <h1 style={{ textAlign: 'center' }}>Resume</h1>
      {/* <h2>Personal Information</h2> */}
      <ul className={classes.pdfUl}>
        <img
          src={imgFt}
          alt=""
          width={100}
          height={100}
          style={{ borderRadius: '50%', position: 'absolute', right: '2rem' }}
        />
        <li>
          <strong>姓名:</strong> {consultant?.basicInfo?.lastName}
          {consultant?.basicInfo?.firstName}
        </li>
        {/* <li>
            <strong>国籍:</strong> China
          </li> */}
        <li>
          <strong>性别:</strong> {consultant?.basicInfo?.gender == 'male' ? '男' : '女'}
        </li>
        <li>
          <strong>邮箱:</strong> {consultant?.basicInfo?.contactEmail}
        </li>
        <li>
          <strong>所在地:</strong>
          {CITY.find(c => c.code == consultant?.basicInfo?.nowAddressCity)?.name}
          <span className={classes.spanSpace}></span>
          {cityChild.find(c => c.code === consultant?.basicInfo?.nowAddressDistrict)?.name}
        </li>
        <li>
          <strong>联系电话:</strong> {consultant?.basicInfo?.phoneNumber}
        </li>
      </ul>
      <h2>自我介绍</h2>
      <ul className={classes.pdfUl}>
        <li>
          <strong>我的优势 :</strong> {consultant?.otherInfo?.description}
        </li>
      </ul>
      <h2>工作要求</h2>
      <ul className={classes.pdfUl}>
        <li>
          <strong>寻找项目 :</strong>
          {consultant?.settings?.findingJob == 'FindingJob' ? '正在寻找项目' : '暂时不考虑寻找项目'}
        </li>
        {/* <li>
            <a href="https://www.baidu.com/" target="_blank" rel="noreferrer">
              baidu
            </a>
          </li> */}
        <li>
          <strong>工作场景:</strong> {workScenario?.remote && '远程'}
          <span className={classes.spanSpace}></span>
          {workScenario?.onsite && '现场'}
        </li>
        <li>
          {/* <strong>Work Mode:</strong> Full-time */}
          <strong>工作方式:</strong>
          {consultant?.settings?.workType == 'FullTimeJob' ? '全职' : '自由职业'}
        </li>

        <li>
          {/* <strong>Expected Hourly Rate:</strong> 200￥ */}
          <strong>期望时薪:</strong> {consultant?.settings?.expectedFeePerHour}
        </li>
        <li>
          <strong>期望月薪:</strong> {consultant?.settings?.expectedFeePerMonth}
          {/* <strong>Expected Monthly Salary:</strong> 30000￥ */}
        </li>
      </ul>
      {/* <h2>Education</h2> */}
      <h2>教育信息</h2>
      {consultant?.educationInfoList?.map((educationItem, index) => {
        return (
          <ul className={classes.pdfUl} key={index}>
            <li>
              <strong>学位:</strong>
              {educationBackground.find(item => item.value === educationItem.education)?.label}
            </li>
            <li>
              <strong>专业:</strong>
              {specialized.find(c => c.value === educationItem.majorType)?.label}
            </li>
            <li>
              <strong>入学时间:</strong>
              {dateFormatYearMonthDay(educationItem.atSchoolDateRangeStart)}
            </li>
            <li>
              <strong>毕业时间:</strong>
              {dateFormatYearMonthDay(educationItem.atSchoolDateRangeEnd)}
            </li>
            <li>
              <strong>学校名称:</strong> {educationItem.schoolName}
            </li>
            <li>
              <strong>专业方向:</strong> {educationItem.academicDirections}
            </li>
          </ul>
        );
      })}

      <div>
        <h2>工作信息</h2>
        {consultant?.workInfoList?.map((item, index) => {
          return (
            <ul className={classes.pdfUl} key={index}>
              <li>
                <strong>公司名称:</strong> {item.companyName}
              </li>
              <li>
                <strong>工作内容:</strong> {item.jobDescription}
              </li>
              <li>
                <strong>行业:</strong>
                {item.industryClass &&
                  resultIndustryView(item.industryClass).map(i => {
                    return (
                      <span style={{ margin: '0 5px' }} key={i}>
                        {i}
                      </span>
                    );
                  })}
              </li>
              <li>
                <strong>专业:</strong>
                {item.professionalClass &&
                  resultProfessional(item.professionalClass).map(i => {
                    return (
                      <span key={i} style={{ margin: '0 5px' }}>
                        {i}
                      </span>
                    );
                  })}
              </li>
              <li>
                <strong>职位名称:</strong> {item.jobTitle}
              </li>
              <li>
                <strong>入职时间:</strong> {dateFormatYearMonthDay(item.atWorkDateRangeStart)}
              </li>
              <li>
                <strong>离职时间:</strong> {dateFormatYearMonthDay(item.atWorkDateRangeEnd)}
              </li>

              {/* <li>
                <strong>工作模式:</strong> Full-time
              </li> */}
            </ul>
          );
        })}
      </div>

      <h2>项目信息</h2>
      {consultant?.projectInfoList?.map((projectItem, index) => {
        return (
          <ul className={classes.pdfUl} key={index}>
            <li>
              <strong>项目名称:</strong>：{projectItem.projectName}
            </li>
            <li>
              <strong>所属公司:</strong> {projectItem.projectCompanyName}
            </li>
            <li>
              <strong>项目描述:</strong> {projectItem.projectDescription}
            </li>
            <li>
              <strong>开始时间:</strong>
              {dateFormatYearMonthDay(projectItem.projectDateStart)}
            </li>
            <li>
              <strong>结束时间:</strong>
              {dateFormatYearMonthDay(projectItem.projectDateEnd)}
            </li>
          </ul>
        );
      })}

      <h2>其他能力</h2>
      <ul className={classes.pdfUl}>
        {consultant?.languageInfoList?.map((languageItem, index) => {
          return (
            <li key={index}>
              <strong>语言:</strong>
              {LANGUAGE_WORLD.find(e => e.value === languageItem.languageName)?.label}
              <span className={classes.spanSpace}></span> <strong>熟练度:</strong>
              {Proficiency_level[languageItem.languageLevel]}
            </li>
          );
        })}
      </ul>
      <h2>个人荣誉</h2>
      {consultant?.honorInfoList?.map((honorItem, index) => {
        return (
          <ul className={classes.pdfUl} key={index}>
            <li>
              <strong>荣誉:</strong> {honorItem.honorTitle}
              <span className={classes.spanSpace}></span>
            </li>

            <li>
              <strong>荣誉简介:</strong>
              {honorItem.honorContent}
            </li>
            <li>
              <strong>开始时间:</strong> {dateFormatYearMonthDay(honorItem.honorDateRangeStart)}
            </li>
            <li>
              <strong>结束时间:</strong> {dateFormatYearMonthDay(honorItem.honorDateRangeEnd)}
            </li>
          </ul>
        );
      })}

      <h2>我的证书</h2>
      {consultant?.certificateInfoList?.map((certificateItem, index) => {
        return (
          <ul className={classes.pdfUl} key={index}>
            <li>
              <strong>证书名称:</strong> {certificateItem.certificateName}
            </li>
            <li>
              <strong>证书类型:</strong>
              {
                CertificateTypeArray.find(c => c.value == certificateItem.certificateDescription)
                  ?.label
              }
            </li>
          </ul>
        );
      })}
      <div className={classes.pdfBodyMark}> {divMark}</div>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  consultantId?: number;
}

export default withStyles(styles)(PdfTemplate);
