export const COUNTRY = [
  // {
  //   name: '',
  //   value: '',
  //   phoneCode: '',
  // },
  {
    name: '中国',
    value: 'China',
    phoneCode: '+86',
  },
  {
    name: '阿富汗',
    value: 'Afghanistan',
    phoneCode: '+93',
  },
  {
    name: '阿尔巴尼亚',
    value: 'Albania',
    phoneCode: '+355',
  },
  {
    name: '阿尔及利亚',
    value: 'Algeria',
    phoneCode: '+213',
  },
  {
    name: '美属萨摩亚',
    value: 'American Samoa',
    phoneCode: '+684',
  },
  {
    name: '安道尔',
    value: 'Andorra',
    phoneCode: '+376',
  },
  {
    name: '安哥拉',
    value: 'Angola',
    phoneCode: '+244',
  },
  {
    name: '安圭拉',
    value: 'Anguilla',
    phoneCode: '+1264',
  },
  {
    name: '南极洲',
    value: 'Antarctica',
    phoneCode: '+672',
  },
  {
    name: '安提瓜和巴布达',
    value: 'Antigua and Barbuda',
    phoneCode: '+1268',
  },
  {
    name: '阿根廷',
    value: 'Argvaluetina',
    phoneCode: '+54',
  },
  {
    name: '亚美尼亚',
    value: 'Armvalueia',
    phoneCode: '+374',
  },
  {
    name: '阿鲁巴',
    value: 'Aruba',
    phoneCode: '+297',
  },
  {
    name: '澳大利亚',
    value: 'Australia',
    phoneCode: '+61',
  },
  {
    name: '奥地利',
    value: 'Austria',
    phoneCode: '+43',
  },
  {
    name: '阿塞拜疆',
    value: 'Azerbaijan',
    phoneCode: '+994',
  },
  {
    name: '巴林',
    value: 'Bahrain',
    phoneCode: '+973',
  },
  {
    name: '孟加拉国',
    value: 'Bangladesh',
    phoneCode: '+880',
  },
  {
    name: '巴巴多斯',
    value: 'Barbados',
    phoneCode: '+1246',
  },
  {
    name: '白俄罗斯',
    value: 'Belarus',
    phoneCode: '+375',
  },
  {
    name: '比利时',
    value: 'Belgium',
    phoneCode: '+32',
  },
  {
    name: '伯利兹',
    value: 'Belize',
    phoneCode: '+501',
  },
  {
    name: '贝宁',
    value: 'Bvaluein',
    phoneCode: '+229',
  },
  {
    name: '百慕大',
    value: 'Bermuda',
    phoneCode: '+1441',
  },
  {
    name: '不丹',
    value: 'Bhutan',
    phoneCode: '+975',
  },
  {
    name: '玻利维亚',
    value: 'Bolivia',
    phoneCode: '+591',
  },
  {
    name: '波黑',
    value: 'Bosnia and Herzegovina',
    phoneCode: '+387',
  },
  {
    name: '博茨瓦纳',
    value: 'Botswana',
    phoneCode: '+267',
  },
  {
    name: '巴西',
    value: 'Brazil',
    phoneCode: '+55',
  },
  {
    name: '英属维尔京群岛',
    value: 'British Virgin Islands',
    phoneCode: '+1284',
  },
  {
    name: '文莱',
    value: 'Brunei Darussalam',
    phoneCode: '+673',
  },
  {
    name: '保加利亚',
    value: 'Bulgaria',
    phoneCode: '+359',
  },
  {
    name: '布基纳法索',
    value: 'Burkina Faso',
    phoneCode: '+226',
  },
  {
    name: '缅甸',
    value: 'Burma',
    phoneCode: '+95',
  },
  {
    name: '布隆迪',
    value: 'Burundi',
    phoneCode: '+257',
  },
  {
    name: '柬埔寨',
    value: 'Cambodia',
    phoneCode: '+855',
  },
  {
    name: '喀麦隆',
    value: 'Cameroon',
    phoneCode: '+237',
  },
  {
    name: '加拿大',
    value: 'Canada',
    phoneCode: '+1',
  },
  {
    name: '佛得角',
    value: 'Cape Verde',
    phoneCode: '+238',
  },
  {
    name: '开曼群岛',
    value: 'Cayman Islands',
    phoneCode: '+1345',
  },
  {
    name: '中非',
    value: 'Cvaluetral African Republic',
    phoneCode: '+236',
  },
  {
    name: '乍得',
    value: 'Chad',
    phoneCode: '+235',
  },
  {
    name: '智利',
    value: 'Chile',
    phoneCode: '+56',
  },

  {
    name: '圣诞岛',
    value: 'Christmas Island',
    phoneCode: '+61',
  },
  {
    name: '科科斯（基林）群岛',
    value: 'Cocos (Keeling) Islands',
    phoneCode: '+61',
  },
  {
    name: '哥伦比亚',
    value: 'Colombia',
    phoneCode: '+57',
  },
  {
    name: '科摩罗',
    value: 'Comoros',
    phoneCode: '+269',
  },
  {
    name: '刚果（金）',
    value: 'Democratic Republic of the Congo',
    phoneCode: '+243',
  },
  {
    name: '刚果（布）',
    value: 'Republic of the Congo',
    phoneCode: '+242',
  },
  {
    name: '库克群岛',
    value: 'Cook Islands',
    phoneCode: '+682',
  },
  {
    name: '哥斯达黎加',
    value: 'Costa Rica',
    phoneCode: '+506',
  },
  {
    name: '科特迪瓦',
    value: "Cote d'Ivoire",
    phoneCode: '+225',
  },
  {
    name: '克罗地亚',
    value: 'Croatia',
    phoneCode: '+385',
  },
  {
    name: '古巴',
    value: 'Cuba',
    phoneCode: '+53',
  },
  {
    name: '塞浦路斯',
    value: 'Cyprus',
    phoneCode: '+357',
  },
  {
    name: '捷克',
    value: 'Czech Republic',
    phoneCode: '+420',
  },
  {
    name: '丹麦',
    value: 'Dvaluemark',
    phoneCode: '+45',
  },
  {
    name: '吉布提',
    value: 'Djibouti',
    phoneCode: '+253',
  },
  {
    name: '多米尼克',
    value: 'Dominica',
    phoneCode: '+1767',
  },
  {
    name: '多米尼加',
    value: 'Dominican Republic',
    phoneCode: '+1809',
  },
  {
    name: '厄瓜多尔',
    value: 'Ecuador',
    phoneCode: '+593',
  },
  {
    name: '埃及',
    value: 'Egypt',
    phoneCode: '+20',
  },
  {
    name: '萨尔瓦多',
    value: 'El Salvador',
    phoneCode: '+503',
  },
  {
    name: '赤道几内亚',
    value: 'Equatorial Guinea',
    phoneCode: '+240',
  },
  {
    name: '厄立特里亚',
    value: 'Eritrea',
    phoneCode: '+291',
  },
  {
    name: '爱沙尼亚',
    value: 'Estonia',
    phoneCode: '+372',
  },
  {
    name: '埃塞俄比亚',
    value: 'Ethiopia',
    phoneCode: '+251',
  },
  {
    name: '福克兰群岛（马尔维纳斯）',
    value: 'Falkland Islands (Islas Malvinas)',
    phoneCode: '+500',
  },
  {
    name: '法罗群岛',
    value: 'Faroe Islands',
    phoneCode: '+298',
  },
  {
    name: '斐济',
    value: 'Fiji',
    phoneCode: '+679',
  },
  {
    name: '芬兰',
    value: 'Finland',
    phoneCode: '+358',
  },
  {
    name: '法国',
    value: 'France',
    phoneCode: '+33',
  },
  {
    name: '法属圭亚那',
    value: 'Frvaluech Guiana',
    phoneCode: '+594',
  },
  {
    name: '法属波利尼西亚',
    value: 'Frvaluech Polynesia',
    phoneCode: '+689',
  },
  {
    name: '加蓬',
    value: 'Gabon',
    phoneCode: '+241',
  },
  {
    name: '格鲁吉亚',
    value: 'Georgia',
    phoneCode: '+995',
  },
  {
    name: '德国',
    value: 'Germany',
    phoneCode: '+49',
  },
  {
    name: '加纳',
    value: 'Ghana',
    phoneCode: '+233',
  },
  {
    name: '直布罗陀',
    value: 'Gibraltar',
    phoneCode: '+350',
  },
  {
    name: '希腊',
    value: 'Greece',
    phoneCode: '+30',
  },
  {
    name: '格陵兰',
    value: 'Grevalueland',
    phoneCode: '+299',
  },
  {
    name: '格林纳达',
    value: 'Grvalueada',
    phoneCode: '+1473',
  },
  {
    name: '瓜德罗普',
    value: 'Guadeloupe',
    phoneCode: '+590',
  },
  {
    name: '关岛',
    value: 'Guam',
    phoneCode: '+1671',
  },
  {
    name: '危地马拉',
    value: 'Guatemala',
    phoneCode: '+502',
  },
  {
    name: '根西岛',
    value: 'Guernsey',
    phoneCode: '+1481',
  },
  {
    name: '几内亚',
    value: 'Guinea',
    phoneCode: '+224',
  },
  {
    name: '几内亚比绍',
    value: 'Guinea-Bissau',
    phoneCode: '+245',
  },
  {
    name: '圭亚那',
    value: 'Guyana',
    phoneCode: '+592',
  },
  {
    name: '海地',
    value: 'Haiti',
    phoneCode: '+509',
  },
  {
    name: '梵蒂冈',
    value: 'Holy See (Vatican City)',
    phoneCode: '+379',
  },
  {
    name: '洪都拉斯',
    value: 'Honduras',
    phoneCode: '+504',
  },
  {
    name: '中国香港',
    value: 'Hong Kong (SAR)',
    phoneCode: '+852',
  },
  {
    name: '匈牙利',
    value: 'Hungary',
    phoneCode: '+36',
  },
  {
    name: '冰岛',
    value: 'Iceland',
    phoneCode: '+354',
  },
  {
    name: '印度',
    value: 'India',
    phoneCode: '+91',
  },
  {
    name: '印度尼西亚',
    value: 'Indonesia',
    phoneCode: '+62',
  },
  {
    name: '伊朗',
    value: 'Iran',
    phoneCode: '+98',
  },
  {
    name: '伊拉克',
    value: 'Iraq',
    phoneCode: '+964',
  },
  {
    name: '爱尔兰',
    value: 'Ireland',
    phoneCode: '+353',
  },
  {
    name: '以色列',
    value: 'Israel',
    phoneCode: '+972',
  },
  {
    name: '意大利',
    value: 'Italy',
    phoneCode: '+39',
  },
  {
    name: '牙买加',
    value: 'Jamaica',
    phoneCode: '+1876',
  },
  {
    name: '日本',
    value: 'Japan',
    phoneCode: '+81',
  },
  {
    name: '约旦',
    value: 'Jordan',
    phoneCode: '+962',
  },
  {
    name: '哈萨克斯坦',
    value: 'Kazakhstan',
    phoneCode: '+73',
  },
  {
    name: '肯尼亚',
    value: 'Kvalueya',
    phoneCode: '+254',
  },
  {
    name: '基里巴斯',
    value: 'Kiribati',
    phoneCode: '+686',
  },
  {
    name: '朝鲜',
    value: 'North Korea',
    phoneCode: '+850',
  },
  {
    name: '韩国',
    value: 'South Korea',
    phoneCode: '+82',
  },
  {
    name: '科威特',
    value: 'Kuwait',
    phoneCode: '+965',
  },
  {
    name: '吉尔吉斯斯坦',
    value: 'Kyrgyzstan',
    phoneCode: '+996',
  },
  {
    name: '老挝',
    value: 'Laos',
    phoneCode: '+856',
  },
  {
    name: '拉脱维亚',
    value: 'Latvia',
    phoneCode: '+371',
  },
  {
    name: '黎巴嫩',
    value: 'Lebanon',
    phoneCode: '+961',
  },
  {
    name: '莱索托',
    value: 'Lesotho',
    phoneCode: '+266',
  },
  {
    name: '利比里亚',
    value: 'Liberia',
    phoneCode: '+231',
  },
  {
    name: '利比亚',
    value: 'Libya',
    phoneCode: '+218',
  },
  {
    name: '列支敦士登',
    value: 'Liechtvaluestein',
    phoneCode: '+423',
  },
  {
    name: '立陶宛',
    value: 'Lithuania',
    phoneCode: '+370',
  },
  {
    name: '卢森堡',
    value: 'Luxembourg',
    phoneCode: '+352',
  },
  {
    name: '中国澳门',
    value: 'Macao',
    phoneCode: '+853',
  },
  {
    name: '前南马其顿',
    value: 'The Former Yugoslav Republic of Macedonia',
    phoneCode: '+389',
  },
  {
    name: '马达加斯加',
    value: 'Madagascar',
    phoneCode: '+261',
  },
  {
    name: '马拉维',
    value: 'Malawi',
    phoneCode: '+265',
  },
  {
    name: '马来西亚',
    value: 'Malaysia',
    phoneCode: '+60',
  },
  {
    name: '马尔代夫',
    value: 'Maldives',
    phoneCode: '+960',
  },
  {
    name: '马里',
    value: 'Mali',
    phoneCode: '+223',
  },
  {
    name: '马耳他',
    value: 'Malta',
    phoneCode: '+356',
  },
  {
    name: '马绍尔群岛',
    value: 'Marshall Islands',
    phoneCode: '+692',
  },
  {
    name: '马提尼克',
    value: 'Martinique',
    phoneCode: '+596',
  },
  {
    name: '毛里塔尼亚',
    value: 'Mauritania',
    phoneCode: '+222',
  },
  {
    name: '毛里求斯',
    value: 'Mauritius',
    phoneCode: '+230',
  },
  {
    name: '马约特',
    value: 'Mayotte',
    phoneCode: '+269',
  },
  {
    name: '墨西哥',
    value: 'Mexico',
    phoneCode: '+52',
  },
  {
    name: '密克罗尼西亚',
    value: 'Federated States of Micronesia',
    phoneCode: '+691',
  },
  {
    name: '摩尔多瓦',
    value: 'Moldova',
    phoneCode: '+373',
  },
  {
    name: '摩纳哥',
    value: 'Monaco',
    phoneCode: '+377',
  },
  {
    name: '蒙古',
    value: 'Mongolia',
    phoneCode: '+976',
  },
  {
    name: '蒙特塞拉特',
    value: 'Montserrat',
    phoneCode: '+1664',
  },
  {
    name: '摩洛哥',
    value: 'Morocco',
    phoneCode: '+212',
  },
  {
    name: '莫桑比克',
    value: 'Mozambique',
    phoneCode: '+258',
  },
  {
    name: '纳米尼亚',
    value: 'Namibia',
    phoneCode: '+264',
  },
  {
    name: '瑙鲁',
    value: 'Nauru',
    phoneCode: '+674',
  },
  {
    name: '尼泊尔',
    value: 'Nepal',
    phoneCode: '+977',
  },
  {
    name: '荷兰',
    value: 'Netherlands',
    phoneCode: '+31',
  },
  {
    name: '荷属安的列斯',
    value: 'Netherlands Antilles',
    phoneCode: '+599',
  },
  {
    name: '新喀里多尼亚',
    value: 'New Caledonia',
    phoneCode: '+687',
  },
  {
    name: '新西兰',
    value: 'New Zealand',
    phoneCode: '+64',
  },
  {
    name: '尼加拉瓜',
    value: 'Nicaragua',
    phoneCode: '+505',
  },
  {
    name: '尼日尔',
    value: 'Niger',
    phoneCode: '+227',
  },
  {
    name: '尼日利亚',
    value: 'Nigeria',
    phoneCode: '+234',
  },
  {
    name: '纽埃',
    value: 'Niue',
    phoneCode: '+683',
  },
  {
    name: '诺福克岛',
    value: 'Norfolk Island',
    phoneCode: '+6723',
  },
  {
    name: '北马里亚纳',
    value: 'Northern Mariana Islands',
    phoneCode: '+1',
  },
  {
    name: '挪威',
    value: 'Norway',
    phoneCode: '+47',
  },
  {
    name: '阿曼',
    value: 'Oman',
    phoneCode: '+968',
  },
  {
    name: '巴基斯坦',
    value: 'Pakistan',
    phoneCode: '+92',
  },
  {
    name: '帕劳',
    value: 'Palau',
    phoneCode: '+680',
  },
  {
    name: '巴拿马',
    value: 'Panama',
    phoneCode: '+507',
  },
  {
    name: '巴布亚新几内亚',
    value: 'Papua New Guinea',
    phoneCode: '+675',
  },
  {
    name: '巴拉圭',
    value: 'Paraguay',
    phoneCode: '+595',
  },
  {
    name: '秘鲁',
    value: 'Peru',
    phoneCode: '+51',
  },
  {
    name: '菲律宾',
    value: 'Philippines',
    phoneCode: '+63',
  },
  {
    name: '波兰',
    value: 'Poland',
    phoneCode: '+48',
  },
  {
    name: '葡萄牙',
    value: 'Portugal',
    phoneCode: '+351',
  },
  {
    name: '波多黎各',
    value: 'Puerto Rico',
    phoneCode: '+1809',
  },
  {
    name: '卡塔尔',
    value: 'Qatar',
    phoneCode: '+974',
  },
  {
    name: '留尼汪',
    value: 'Reunion',
    phoneCode: '+262',
  },
  {
    name: '罗马尼亚',
    value: 'Romania',
    phoneCode: '+40',
  },
  {
    name: '俄罗斯',
    value: 'Russia',
    phoneCode: '+7',
  },
  {
    name: '卢旺达',
    value: 'Rwanda',
    phoneCode: '+250',
  },
  {
    name: '圣赫勒拿',
    value: 'Saint Helvaluea',
    phoneCode: '+290',
  },
  {
    name: '圣基茨和尼维斯',
    value: 'Saint Kitts and Nevis',
    phoneCode: '+1869',
  },
  {
    name: '圣卢西亚',
    value: 'Saint Lucia',
    phoneCode: '+1758',
  },
  {
    name: '圣皮埃尔和密克隆',
    value: 'Saint Pierre and Miquelon',
    phoneCode: '+508',
  },
  {
    name: '圣文森特和格林纳丁斯',
    value: 'Saint Vincvaluet and the Grvalueadines',
    phoneCode: '+1784',
  },
  {
    name: '萨摩亚',
    value: 'Samoa',
    phoneCode: '+685',
  },
  {
    name: '圣马力诺',
    value: 'San Marino',
    phoneCode: '+378',
  },
  {
    name: '圣多美和普林西比',
    value: 'Sao Tome and Principe',
    phoneCode: '+239',
  },
  {
    name: '沙特阿拉伯',
    value: 'Saudi Arabia',
    phoneCode: '+966',
  },
  {
    name: '塞内加尔',
    value: 'Svalueegal',
    phoneCode: '+221',
  },
  {
    name: '塞尔维亚和黑山',
    value: 'Serbia and Montvalueegro',
    phoneCode: '+381',
  },
  {
    name: '塞舌尔',
    value: 'Seychelles',
    phoneCode: '+248',
  },
  {
    name: '塞拉利',
    value: 'Sierra Leone',
    phoneCode: '+232',
  },
  {
    name: '新加坡',
    value: 'Singapore',
    phoneCode: '+65',
  },
  {
    name: '斯洛伐克',
    value: 'Slovakia',
    phoneCode: '+421',
  },
  {
    name: '斯洛文尼亚',
    value: 'Slovvalueia',
    phoneCode: '+386',
  },
  {
    name: '所罗门群岛',
    value: 'Solomon Islands',
    phoneCode: '+677',
  },
  {
    name: '索马里',
    value: 'Somalia',
    phoneCode: '+252',
  },
  {
    name: '南非',
    value: 'South Africa',
    phoneCode: '+27',
  },
  {
    name: '西班牙',
    value: 'Spain',
    phoneCode: '+34',
  },
  {
    name: '斯里兰卡',
    value: 'Sri Lanka',
    phoneCode: '+94',
  },
  {
    name: '苏丹',
    value: 'Sudan',
    phoneCode: '+249',
  },
  {
    name: '苏里南',
    value: 'Suriname',
    phoneCode: '+597',
  },
  {
    name: '斯瓦尔巴岛和扬马延岛',
    value: 'Svalbard',
    phoneCode: '+47',
  },
  {
    name: '斯威士兰',
    value: 'Swaziland',
    phoneCode: '+268',
  },
  {
    name: '瑞典',
    value: 'Swedvalue',
    phoneCode: '+46',
  },
  {
    name: '瑞士',
    value: 'Switzerland',
    phoneCode: '+41',
  },
  {
    name: '叙利亚',
    value: 'Syria',
    phoneCode: '+963',
  },
  {
    name: '中国台湾',
    value: 'Taiwan',
    phoneCode: '+886',
  },
  {
    name: '塔吉克斯坦',
    value: 'Tajikistan',
    phoneCode: '+992',
  },
  {
    name: '坦桑尼亚',
    value: 'Tanzania',
    phoneCode: '+255',
  },
  {
    name: '泰国',
    value: 'Thailand',
    phoneCode: '+66',
  },
  {
    name: '巴哈马',
    value: 'The Bahamas',
    phoneCode: '+1242',
  },
  {
    name: '冈比亚',
    value: 'The Gambia',
    phoneCode: '+220',
  },
  {
    name: '多哥',
    value: 'Togo',
    phoneCode: '+228',
  },
  {
    name: '托克劳',
    value: 'Tokelau',
    phoneCode: '+690',
  },
  {
    name: '汤加',
    value: 'Tonga',
    phoneCode: '+676',
  },
  {
    name: '特立尼达和多巴哥',
    value: 'Trinidad and Tobago',
    phoneCode: '+1868',
  },
  {
    name: '突尼斯',
    value: 'Tunisia',
    phoneCode: '+216',
  },
  {
    name: '土耳其',
    value: 'Turkey',
    phoneCode: '+90',
  },
  {
    name: '土库曼斯坦',
    value: 'Turkmvalueistan',
    phoneCode: '+993',
  },
  {
    name: '特克斯和凯科斯群岛',
    value: 'Turks and Caicos Islands',
    phoneCode: '+1649',
  },
  {
    name: '图瓦卢',
    value: 'Tuvalu',
    phoneCode: '+688',
  },
  {
    name: '乌干达',
    value: 'Uganda',
    phoneCode: '+256',
  },
  {
    name: '乌克兰',
    value: 'Ukraine',
    phoneCode: '+380',
  },
  {
    name: '阿拉伯联合酋长国',
    value: 'United Arab Emirates',
    phoneCode: '+971',
  },
  {
    name: '英国',
    value: 'United Kingdom',
    phoneCode: '+44',
  },
  {
    name: '美国',
    value: 'United States',
    phoneCode: '+1',
  },
  {
    name: '乌拉圭',
    value: 'Uruguay',
    phoneCode: '+598',
  },
  {
    name: '乌兹别克斯坦',
    value: 'Uzbekistan',
    phoneCode: '+998',
  },
  {
    name: '瓦努阿图',
    value: 'Vanuatu',
    phoneCode: '+678',
  },
  {
    name: '委内瑞拉',
    value: 'Vvalueezuela',
    phoneCode: '+58',
  },
  {
    name: '越南',
    value: 'Vietnam',
    phoneCode: '+84',
  },
  {
    name: '美属维尔京群岛',
    value: 'Virgin Islands',
    phoneCode: '+1340',
  },
  {
    name: '瓦利斯和富图纳',
    value: 'Wallis and Futuna',
    phoneCode: '+681',
  },
  {
    name: '也门',
    value: 'Yemvalue',
    phoneCode: '+967',
  },
  {
    name: '赞比亚',
    value: 'Zambia',
    phoneCode: '+260',
  },
  {
    name: '津巴布韦',
    value: 'Zimbabwe',
    phoneCode: '+263',
  },
];

export default COUNTRY;
